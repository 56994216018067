// import { useEffect, useRef } from 'react'
// export default function Advertisement_160X600() {
//     const banner = useRef(null)

//     const atOptions = {
//         key: 'fde35a75f8b625b61bc78e8db4e6f0f9',
//         format: 'iframe',
//         height: 600,
//         width: 160,
//         params: {},
//     }
//     useEffect(() => {
//     if (banner.current && !banner.current.firstChild) {
//         const conf = document.createElement('script')
//         const script = document.createElement('script')
//         script.type = 'text/javascript'
//         script.src = `//www.profitablecreativeformat.com/fde35a75f8b625b61bc78e8db4e6f0f9/invoke.js/${atOptions.key}/invoke.js`
//         conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

//         banner.current.append(conf)
//         banner.current.append(script)
//     }
// }, [banner])

//     return <div  ref={banner}></div>
// }


import React, { useEffect, useRef, useState } from 'react';

export default function Advertisement_160X600() {
    const banner = useRef(null);
    const scriptRef = useRef(null);
    const [isScriptInserted, setIsScriptInserted] = useState(false);
    const [scriptLoader, setScriptLoader] = useState(false)

    const atOptions = {
        key: 'fde35a75f8b625b61bc78e8db4e6f0f9',
        format: 'iframe',
        height: 600,
        width: 160,
        params: {},
    };

    const insertScript = () => {
        if (banner.current && !isScriptInserted) {
            const scriptElement = document.createElement('script');
            const conf = document.createElement('script');
    
            scriptElement.type = 'text/javascript';
            scriptElement.src = `//www.profitablecreativeformat.com/fde35a75f8b625b61bc78e8db4e6f0f9/invoke.js/${atOptions.key}/invoke.js`;
            // scriptElement.setAttribute
            // {
            //     async
            // }
            // assign an onload event handler
            scriptElement.addEventListener('load', (event) => {
                setScriptLoader(true);
        // console.log('app.js file has been loaded');
    });
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
    
            // Check if banner.current still exists before appending children
            if (banner.current) {
                banner.current.appendChild(conf);
                banner.current.appendChild(scriptElement);
            }
    
            scriptRef.current = scriptElement;
            setIsScriptInserted(true);
        }
    };
    

    const removeScript = () => {
        
        if (banner.current  && isScriptInserted && scriptRef.current ) {
            while (banner.current.firstChild) {
                                banner.current.removeChild(banner.current.firstChild)
                            }
                            setIsScriptInserted(false);
        }
    };

    useEffect(() => {
        if (banner.current && !isScriptInserted && !scriptLoader) {
        const scriptElement = document.createElement('script');
            const conf = document.createElement('script');
            scriptElement.async = true;
conf.async = true;
            scriptElement.type = 'text/javascript';
            scriptElement.src = `//www.profitablecreativeformat.com/fde35a75f8b625b61bc78e8db4e6f0f9/invoke.js/${atOptions.key}/invoke.js`;
            // scriptElement.setAttribute
            // {
            //     async
            // }
            // assign an onload event handler
            scriptElement.addEventListener('load', (event) => {
                setScriptLoader(true);
        // console.log('app.js file has been loaded');
    });
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
    
            // Check if banner.current still exists before appending children
            if (banner.current) {
                banner.current.appendChild(conf);
                banner.current.appendChild(scriptElement);
            }
    
            //scriptRef.current = scriptElement;
            setIsScriptInserted(true);

        return () => {
            // Cleanup if needed when the component unmounts
            conf.parentNode.removeChild(conf);  
            scriptElement.parentNode.removeChild(scriptElement);
        };
    }
    }, []);
    return (
        <>
            <div ref={banner} style={{width:"160px", height:"600px"}}></div>
            {/* <button onClick={removeScript}>Remove Script</button> */}
        </>
    );
}
