import { configureStore } from "@reduxjs/toolkit";
import contact from "../allslice/contactslice";
import register from "../allslice/registerslice";
import userForgotPasswordData from "../allslice/forgotresetpwdslice"
import coins from "../allslice/collectcoinslice"
import shop from "../allslice/shoplistslice";
import leader from "../allslice/leaderboardslice";
import profile from "../allslice/myprofileslice";
import levelupdate from "../allslice/updatelevelslice";
import dashborad from "../allslice/dashboradslice";


export const store = configureStore({
  reducer: {
  contact: contact,
  register:register,
  userForgotPasswordData:userForgotPasswordData,
  coins:coins,
  shop:shop,
  leader:leader,
  profile:profile,
  levelupdate:levelupdate,
  dashboard:dashborad

  }
});
