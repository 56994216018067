import React, { useState } from "react";
import '../App.css';
import { useNavigate } from "react-router-dom";
import grateblitz from '../assests/images/0001_grateblitz.webp'
import paintball from '../assests/images/0002_paintballparty.webp'
import holefillerpro from '../assests/images/0003_holefillerpro.webp'
import cubecraze from '../assests/images/0004_cubecraze.webp'
import parkingpro from '../assests/images/0015_parkingproxtreme.webp'
import tophillhero from '../assests/images/0016_hilltopheroes.webp'
import sugarlands from '../assests/images/0017_sugarlandadventures.webp'
import masterfufighter from '../assests/images/0006_masterfufighter.webp'
import wordhunters from '../assests/images/0019_wordhunterchallenge.webp'
import ringofflame from '../assests/images/0005_ringsofflames.webp'
import wheelsofescape from '../assests/images/0021_wheelsofescape.webp'
import mandalamagicbook from '../assests/images/0012_mandalamagicbook.webp'
import rockcriprivals from '../assests/images/0010_rockgriprivals.webp'
import gosurvivorgo from '../assests/images/0007_gosurvivorgo.webp'
import fingersoccerfrenzy from '../assests/images/0008_fingersoccerfrenzy.webp'
import spotthedifference from '../assests/images/0011_spotthedifference.webp'
import runnerella from '../assests/images/0014_runnerella.webp'
import catwalkclash from '../assests/images/0009_catwalkclash.webp'
import rescueropes from '../assests/images/0036_rescueropes.webp'
import theultimateimposter from '../assests/images/0013_theultimateimposter.webp'
import goodvsevil from '../assests/images/0031_goodvsevil.webp'
import tyrespeedthrill from '../assests/images/0022_tyrespeedthrill.webp'
import glassbreakerbaseball from '../assests/images/0023_glassbreakerbaseball.webp'
import rooftopracer from '../assests/images/0032_rooftopracer.webp'
import poseyourwarrior from '../assests/images/0035_poseyourwarrior.webp'
import hoopstackcraze from '../assests/images/0027_hoopstackcraze.webp'
import escaperunners from '../assests/images/0025_escaperunners.webp'
import hunterhousehero from '../assests/images/0024_hauntedhousehero.webp'
import speedyhands from '../assests/images/0029_speedyhands.webp'
import racetherails from '../assests/images/0026_racetherails.webp'
import sawbladepuzzle from '../assests/images/0028_sawbladepuzzle.webp'
import rescuebrigade from '../assests/images/0030_rescuebrigade.webp'
import pulltosurvive from '../assests/images/0033_pushtosurvive.webp'
import roberryrun3d from '../assests/images/0038_robberyrun3d.webp'
import adventuroustruckdriving from '../assests/images/0037_adventuroustruckdriving.webp'
import fighterchamp from '../assests/images/0040_fighterchamp.webp'
import goldrushestates from '../assests/images/0034_goldrushestates.webp'
import spidermanunleased from '../assests/images/0039_spidermanunleased.webp'
import carstunt3d from '../assests/images/0041_carstunt3d.webp'
import lockdownchallenge from '../assests/images/0042_lockdownchallenge.webp'
import holedominator from '../assests/images/0046_holedominator.webp'
import lootquestbossedition from '../assests/images/0044_lootquestbossedition.webp'
import unlockthewrench from '../assests/images/0043_unlockthewrench.webp'
import flipitproedition from '../assests/images/0048_flipitproedition.webp'
import ballcollectorchallenge from '../assests/images/0045_ballcollectorchallenge.webp'
import passengerpickup from '../assests/images/0047_passengerpickup.webp'
import stampit from '../assests/images/0058_stampit.webp'
import soccershaolinlegends from '../assests/images/0049_soccershaolinlegends.webp'
import brickbreakerbash from '../assests/images/0059_brickbreakerbash.webp'
import hangingherohavoc from '../assests/images/0052_hangingherohavoc.webp'
import unblockthesweets from '../assests/images/0050_unblockthesweets.webp'
import cityheroesunited from '../assests/images/0053_cityheroesunited.webp'
import daringtrapescape from '../assests/images/0051_daringtrapeescape.webp'
import applecollectorconquest from '../assests/images/0060_applecollectorsconquest.webp'
import crushingchaoschallenge from '../assests/images/0054_crushingchaoschallenge.webp'
import xtremeskatesaga from '../assests/images/0055_xtremeskatersaga.webp'
import canhittersdelight from '../assests/images/0061_canhittersdelight.webp'
import ambulancerescueracer from '../assests/images/0062_ambulancerescueracer.webp'
import fallinadventurer from '../assests/images/0057_fallingadventurer.webp'
import pinthesticker from '../assests/images/0111_pinthesticker.webp'
import glassbridgethrill from '../assests/images/0056_glassbridgethrill.webp'
import busdriverpro from '../assests/images/0067_busdriverpro.webp'
import carstuntshowdown from '../assests/images/0066_carstuntshowdown.webp'
import blockburst from '../assests/images/0020_blockburst.webp'
import pacmania from '../assests/images/0018_pacmania.webp'
import deepflipdive from '../assests/images/0063_deepflipdive.webp'
import ultimatesportscarpark from '../assests/images/0064_ultimatespotrscarpark.webp'
import soccerstarstriker from '../assests/images/0065_soccerstarstriker.webp'
import diamondhaunters from '../assests/images/0068_diamondhaunters.webp'
import ludolegends from '../assests/images/0069_ludolegends.webp'
import blackjackbrilliance from '../assests/images/0070_blackjackbrilliance.webp';
import baccaratelite from '../assests/images/0071_baccaratelite.webp'
import texastriumppoker from '../assests/images/0074_texastriumppoker.webp'
import horseriding from '../assests/images/0072_horseriding.webp'
import sneakpeekslots from '../assests/images/0073_sneakpeekslots.webp'
import solitudesollitare from '../assests/images/0075_solitudesolitarie.webp'
import deepbluesurvivalslots from '../assests/images/0076_deepbluesurvivalslots.webp'
import rummyroyaleonline from '../assests/images/0077_rummyroyaleonline.webp'
import pokertexasholdemtycoon from '../assests/images/0080_pokertexasholdemtycoon.webp'
import casinoslotsmaster from '../assests/images/0079_casinoslotsmaster.webp'
import stealthstrikeforce from '../assests/images/0085_stealthstrikeforce.webp'
import turbostricksoccer from '../assests/images/0089_turbostrikesoccer.webp'
import snakesandladdersvictory from '../assests/images/0081_snakeandladdervictory.webp'
import wackyracers from '../assests/images/0083_wackyracers.webp'
import bubbleburstbash from '../assests/images/0086_bubbleburstbash.webp'
import vegasvictoryslots from '../assests/images/0078_vegasvictoryslots.webp'
import sliceandstrikebowling from '../assests/images/0087_sliceandstrikeblowing.webp'
import grandchess3d from '../assests/images/0082_grandchess3d.webp'
import mysticmarbleballs from '../assests/images/0090_mysticmarbleballs.webp'
import ladderLeapaton from '../assests/images/0093_ladderleapthon.webp'
import discmasterpro from '../assests/images/0091_discmasterpro.webp'
import therestaurant from '../assests/images/0095_the restaurant.webp'
import stellarstrikers from '../assests/images/0084_stellarstrikers.webp'
import casinoslotsymphony from '../assests/images/0092_casinoslotsymphony.webp'
import ultimatecarstunt from '../assests/images/0097_ultimatecarstunt.webp'
import shadowstriker from '../assests/images/0088_shadowstriker.webp'
import turbotwistracing from '../assests/images/0094_turbotwistracing.webp'
import blockblastexpress from '../assests/images/0098_blockburstexpress.webp'
import shootersstrife from '../assests/images/0099_shooterstrife.webp'
import fruitninjafury from '../assests/images/0096_fruitninjafury.webp'
import skystriker from '../assests/images/0101_skystriker.webp'
import slidetheball from '../assests/images/0103_slidetheball.webp'
import stickmansoccerstrike from '../assests/images/0105_stickmansoccerstrike.webp'
import woodblockpuzzler from '../assests/images/0106_woodblockspuzzler.webp'
import archeyapex from '../assests/images/0100_archerapex.webp'
import gemglorygala from '../assests/images/0107_gemglorygala.webp'
import xtremerikshawstunt from '../assests/images/0109_xtremerickshawstunt.webp'
import bikestuntpro from '../assests/images/0110_bikestuntpro.webp'
import grandslamtennis from '../assests/images/0104_grandslamtennis.webp'
import zombiestrikeforce from '../assests/images/0102_zombiestrikeforce.webp'
import smasherspeedway from '../assests/images/0108_smashersspeedway.webp'
import fairylandprincessmakeover from '../assests/images/0112_fairylandprincessmakeover.webp'
import offroadbikerrace from '../assests/images/0113_offroadbikerrace.webp'
import archeryavenger from '../assests/images/0114_archeryavenger.webp'
import rubikcube from '../assests/images/0115_rubikcube.webp'
import flightracerpro from '../assests/images/0116_flightracerpro.webp'
import golfmania from '../assests/images/0117_golfmania.webp'
import connectthedotsdeluxe from '../assests/images/0118_connectthedotsdeluxe.webp'
import tennisturbotango from '../assests/images/0119_tennisturbotango.webp'
import unblocktheblock from '../assests/images/0120_unblocktheblock.webp'
import crosswordcrossover from '../assests/images/0121_crosswordcrossover.webp'
import mathballchallenge from '../assests/images/0122_mathballchallenge.webp'
import ballzbalancermarathon from '../assests/images/0123_ballzbalancermarathon.webp'
import brainballgala from '../assests/images/0124_brainballgala.webp'
import templerunprincessedition from '../assests/images/0126_templerunprincessedition.webp'
import bowlingzone from '../assests/images/0127_bowlingzone.webp'
import colorropeconnect from '../assests/images/0128_colorropeconnect.webp'
import ballpoolpro from '../assests/images/0129_ballpoolpro.webp'
import racingrebels from '../assests/images/0130_racingrebels.webp'
import bmxcyclethrillrush from '../assests/images/0131_bmxcyclethrillrush.webp'
import highwayroadster from '../assests/images/0132_highwayroadster.webp'
import drawlinepuzzledaze from '../assests/images/0133_drawlinepuzzledaze.webp'
import magicball from '../assests/images/0134_magicball.webp'
import slideitblock from '../assests/images/0135_slideitblock.webp'
import ultimatefootballleague from '../assests/images/0136_ultimatefootballleague.webp'
import guesstheemojiword from '../assests/images/0137_guesstheemojiword.webp'
import aquaccolorsortcraze from '../assests/images/0138_aquaccolorsortcraze.webp'
import metalringdropper from '../assests/images/0139_metalrindropper.webp'
import birdshuffle from '../assests/images/0140_birdshuffle.webp'
import jewelstarblockpuzzle from '../assests/images/0141_jewlstarblockpuzzle.webp'
import ghostlyhunter from '../assests/images/0142_ghostlyhunter.webp'
import cricketworldseries from '../assests/images/0143_cricketworldseries.webp'
import cubefusion from '../assests/images/0144_cubefusion.webp'
import tiletacticmatch3 from '../assests/images/0145_tiletacticmatch3.webp'
import waterwars from '../assests/images/0146_waterwars.webp'
import nutsboltsmanager from '../assests/images/0147_nuts&boltsmanager.webp'
import elevatorrescue from '../assests/images/0148_elevatorrescue.webp'
import wordseekersafari from '../assests/images/0149_wordseekersafari.webp'
import twistedrails from '../assests/images/0150_twistedtrails.webp'
import ropetanglechallenge from '../assests/images/0151_ropetanglechallenge.webp'
import theoffice from '../assests/images/0152_theoffice.webp'
import matchup from '../assests/images/0153_matchup.webp'
import drawingincomplete from '../assests/images/0154_drawingincomplete.webp'
import kittystretchquest from '../assests/images/0155_kittystretchquest.webp'
import tallrunnerdash from '../assests/images/0156_tallrunnerdash.webp'
import dogrescue from '../assests/images/0157_dogrescue.webp'
import sausagesurvivalsaga from '../assests/images/0158_sausagesurvivalsaga.webp'
import mergeletterfusion from '../assests/images/0159_mergeletterfusion.webp'
import monstermakeovermagic from '../assests/images/0160_monstermakeovermagic.webp'
import digandguidechallenge from '../assests/images/0161_digandguidechallenge.webp'
import massmastery from '../assests/images/0162_massmastery.webp'
import animalkingdompuzzler from '../assests/images/0163_animalkingdompuzzler.webp'
import boatracerfury from '../assests/images/0164_boatracerfury.webp'
import colorballthrow from '../assests/images/0164_colorballthrow.webp'
import fruitfrenzypuzzle from '../assests/images/0165_fruitfrenzypuzzle.webp'
import mazeblaster from '../assests/images/0166_mazeblaster.webp'
import skyherofirefighter from '../assests/images/0167_skyherofirefighter.webp'
import goalgalorestadium from '../assests/images/0168_goalgalorestadium.webp'
import sketchparking from '../assests/images/0169_sketchparking.webp'
import iceballrace from '../assests/images/0170_iceballrace.webp'
import skylinediskmaster from '../assests/images/0171_skylinediskmaster.webp'
import blockfilloverload from '../assests/images/0172_blockfilloverload.webp'
import boxrushexpedition from '../assests/images/0173_boxrushexpedition.webp'
import cubestacksprint from '../assests/images/0174_cubestacksprint.webp'
import minermergemania from '../assests/images/0175_minermergemania.webp'
import ringstacker from '../assests/images/0176_ringstacker.webp'
import teamclashrunner from '../assests/images/0177_teamclashrunner.webp'
import paintparade from '../assests/images/0178_paintparade.webp'
import gemhexapuzzle from '../assests/images/0179_gemhexapuzzle.webp'
import boxbalancebattle from '../assests/images/0125_boxbalancebattle.webp'



// import rglogo from '../assests/images/rglogo.webp'



// import tempsplash from '../assests/images/tempsplash.webp'


const Othergames = () => {


  const [items, setItems1] = useState([

    {
      category: 'Other games',
      tile: 6,
      isLeftScrollVisible: false,
      isRightScrollVisible: false,
      items: [
        { id: 1, image: grateblitz, name: 'Grate Blitz', navigate: "https://playrevolutiongames.com/grate-blitz",  },
        { id: 2, image: paintball, name: "Paint Ball Party", navigate: "https://playrevolutiongames.com/paint-ball-party",  },
        { id: 3, image: holefillerpro, name: 'Hole Filler Pro', navigate: "https://playrevolutiongames.com/hole-filler-pro",  },
        { id: 4, image: cubecraze, name: 'Cube Craze', navigate: "https://playrevolutiongames.com/cube-craze",  },
        { id: 5, image: ringofflame, name: 'Rings Of Flames', navigate: 'https://playrevolutiongames.com/rings-of-flames',  },
        { id: 6, image: fingersoccerfrenzy, name: 'Finger Soccer Frenzy',navigate:'https://playrevolutiongames.com/finger-soccer-frenzy',   },
        { id: 7, image: catwalkclash,name: "Catwalk Clash",navigate:'https://playrevolutiongames.com/catwalk-clash',  },
        { id: 8, image: rockcriprivals, name: 'Rock Grip Rivals', navigate: 'https://playrevolutiongames.com/rock-grip-rivals',  },
        { id: 9, image: spotthedifference, name: 'Spot The Difference', navigate: 'https://playrevolutiongames.com/spot-the-difference',  },
        { id: 10, image: mandalamagicbook, name: 'Mandala Magic Book', navigate: 'https://playrevolutiongames.com/mandala-magic-book',  },
        { id: 11, image: theultimateimposter, name: 'The Ultimate Imposter', navigate: 'https://playrevolutiongames.com/the-ultimate-imposter',  },
        { id: 12, image: parkingpro, name: 'Parking Pro Xtreme', navigate: "https://playrevolutiongames.com/parking-pro-xtreme",  },
        { id: 13, image: tophillhero, name: 'Hill Top Heroes', navigate:'https://playrevolutiongames.com/hill-top-heroes',  },
        { id: 14, image: sugarlands, name: 'Sugarland Adventures', navigate: 'https://playrevolutiongames.com/sugarland-adventures',  },
        { id: 15, image: pacmania, name: 'Pacmania', navigate: 'https://playrevolutiongames.com/pacmania',  },
        { id: 16, image: wordhunters, name: "Word Hunter's Challenge", navigate: 'https://playrevolutiongames.com/word-hunter-challenge',  },
        { id: 17, image: wheelsofescape, name: 'Wheels Of Escape', navigate: 'https://playrevolutiongames.com/wheels-of-escape',  },
        { id: 18, image: tyrespeedthrill, name: 'Tyre Speed Thrill', navigate: 'https://playrevolutiongames.com/tyre-speed-thrill',  },
        { id: 19, image: glassbreakerbaseball, name: 'Glass Breaker Baseball', navigate: 'https://playrevolutiongames.com/glass-breaker-baseball',  },
        { id: 20, image: escaperunners, name: 'Escape Runners',navigate:'https://playrevolutiongames.com/escape-runners',  },
        { id: 21, image: racetherails, name: 'Race The Rails', navigate: 'https://playrevolutiongames.com/race-the-rails',  },
        { id: 22, image: hoopstackcraze, name: 'Hoop Stack Craze', navigate: 'https://playrevolutiongames.com/hoop-stack-craze',  },
        { id: 23, image: sawbladepuzzle, name: 'Saw Blade Puzzle', navigate: 'https://playrevolutiongames.com/saw-blade-puzzle',  },
        { id: 24, image: speedyhands, name: 'Speedy Hands', navigate: 'https://playrevolutiongames.com/speedy-hands',  },
        { id: 25, image: rescuebrigade, name: 'Rescue Brigade', navigate: 'https://playrevolutiongames.com/rescue-brigade',  },
        { id: 26, image: goodvsevil, name: 'Good vs Evil', navigate: 'https://playrevolutiongames.com/good-vs-evil',  },
        { id: 27, image: poseyourwarrior, name: 'Pose Your Warrior', navigate: 'https://playrevolutiongames.com/pose-your-warrior',  },
        { id: 28, image: rooftopracer, name: 'Rooftop Racer', navigate: 'https://playrevolutiongames.com/rooftop-racer',  },
        { id: 29, image: pulltosurvive, name: 'Push To Survive', navigate: 'https://playrevolutiongames.com/push-to-survive',  },
        { id: 30, image: adventuroustruckdriving, name: 'Adventurous Truck Driving', navigate: 'https://playrevolutiongames.com/adventurous-truck-driving',  },
        { id: 31, image: fighterchamp, name: 'Fighter Champ',navigate:'https://playrevolutiongames.com/fighter-champ',   },
        { id: 32, image: goldrushestates, name: 'Gold Rush Estates', navigate: 'https://playrevolutiongames.com/gold-rush-estates',  },
        { id: 33, image: roberryrun3d, name: 'Robbery Run 3D', navigate: 'https://playrevolutiongames.com/robbery-run-3d',  },
        { id: 34, image: unlockthewrench, name: 'Unlock The Wrench', navigate: 'https://playrevolutiongames.com/unlock-the-wrench',  },
        { id: 35, image: lootquestbossedition, name: 'Loot Quest: Boss Edition', navigate: 'https://playrevolutiongames.com/loot-quest-boss-edition',  },
        { id: 36, image: ballcollectorchallenge, name: 'Ball Collector Challenge', navigate: 'https://playrevolutiongames.com/ball-collector-challenge',  },
        { id: 37, image: carstunt3d, name: 'Car Stunt Thrills 3D', navigate: 'https://playrevolutiongames.com/car-stunt-thrills-3d',  },
        { id: 38, image: lockdownchallenge, name: 'Lockdown Challenge', navigate: 'https://playrevolutiongames.com/lockdown-challenge',  },
        { id: 39, image: rescueropes, name: 'Rescue Ropes', navigate: 'https://playrevolutiongames.com/rescue-ropes',  },
        { id: 40, image: cityheroesunited, name: 'City Heroes United', navigate: 'https://playrevolutiongames.com/city-heroes-united',  },
        { id: 41, image: offroadbikerrace, name: 'Offroad Biker Race', navigate: 'https://playrevolutiongames.com/offroad-biker-race',  },
        { id: 42, image: flipitproedition, name: 'Flip It! Pro Edition', navigate: 'https://playrevolutiongames.com/flip-it-pro-edition',  },
        { id: 43, image: blockburst, name: 'Block Burst', navigate: 'https://playrevolutiongames.com/block-burst',  },
        { id: 44, image: soccershaolinlegends, name: 'Soccer Shaolin Legends', navigate: 'https://playrevolutiongames.com/soccer-shaolin-legends',  },
        { id: 45, image: hangingherohavoc, name: 'Hanging Hero Havoc', navigate: 'https://playrevolutiongames.com/hanging-hero-havoc',  },
        { id: 46, image: daringtrapescape, name: 'Daring Trap Escape', navigate: 'https://playrevolutiongames.com/daring-trap-escape',  },
        { id: 47, image: unblockthesweets, name: 'Unblock The Sweets', navigate: 'https://playrevolutiongames.com/unblock-the-sweets',  },
        { id: 48, image: holedominator, name: 'Hole Dominator', navigate: 'https://playrevolutiongames.com/hole-dominator',  },
        { id: 49, image: passengerpickup, name: 'Passenger Pickup', navigate: 'https://playrevolutiongames.com/passenger-pickup',  },
        { id: 50, image: crushingchaoschallenge, name: 'Crushing Chaos Challenge', navigate: 'https://playrevolutiongames.com/crushing-chaos-challenge',  },
        { id: 51, image: xtremeskatesaga, name: 'Xtreme Skater Saga', navigate: 'https://playrevolutiongames.com/xtreme-skater-saga',  },
        { id: 52, image: glassbridgethrill, name: 'Glass Bridge Thrill', navigate: 'https://playrevolutiongames.com/glass-bridge-thrill',  },
        { id: 53, image: fallinadventurer, name: 'Falling Adventurer', navigate: 'https://playrevolutiongames.com/falling-adventurer',  },
        { id: 54, image: applecollectorconquest, name: "Apple Collector's Conquest", navigate: 'https://playrevolutiongames.com/apple-collector-conquest',  },
        { id: 55, image: stampit, name: 'Stamp It!', navigate: 'https://playrevolutiongames.com/stamp-it',  },
        { id: 56, image: brickbreakerbash, name: 'Brick Breaker Bash',  navigate:'https://playrevolutiongames.com/brick-breaker-bash', },
        { id: 57, image: canhittersdelight, name: "Can Hitter's Delight", navigate: 'https://playrevolutiongames.com/can-hitter-delight',  },
        { id: 58, image: ambulancerescueracer, name: 'Ambulance Rescue Racer', navigate: 'https://playrevolutiongames.com/ambulance-rescue-racer',  },
        { id: 59, image: deepflipdive, name: 'Deep Flip Dive', navigate: 'https://playrevolutiongames.com/deep-flip-dive',  },
        { id: 60, image: soccerstarstriker, name: 'Soccer star striker', navigate: "https://playrevolutiongames.com/soccer-star-striker",  },
        { id: 61, image: texastriumppoker, name: 'Texas Triumph Poker', navigate: 'https://playrevolutiongames.com/texas-triumph-poker',  },
        { id: 62, image: ultimatesportscarpark, name: 'Ultimate Sports Car Park', navigate: 'https://playrevolutiongames.com/ultimate-sports-car-park',  },
        { id: 63, image: carstuntshowdown, name: 'Car Stunt showdown', navigate: 'https://playrevolutiongames.com/car-stunt-showdown',  },
        { id: 64, image: busdriverpro, name: 'Bus Driver Pro', navigate: 'https://playrevolutiongames.com/bus-driver-pro',  },
        { id: 65, image: diamondhaunters, name: 'Diamond Haunters', navigate: 'https://playrevolutiongames.com/diamond-haunters',  },
        { id: 66, image: sneakpeekslots, name: 'Sneak Peek Slots', navigate: 'https://playrevolutiongames.com/sneak-peek-slots',  },
        { id: 67, image: blackjackbrilliance, name: 'Blackjack Brilliance', navigate: 'https://playrevolutiongames.com/blackjack-brilliance',  },
        { id: 68, image: solitudesollitare, name: 'Solitude Solitaire', navigate: 'https://playrevolutiongames.com/solitude-solitaire',  },
        { id: 69, image: deepbluesurvivalslots, name: 'Deep Blue Survival Slots', navigate: 'https://playrevolutiongames.com/deep-blue-survival-slots',  },
        { id: 70, image: baccaratelite, name: 'Baccarat Elite', navigate: 'https://playrevolutiongames.com/baccarat-elite',  },
        { id: 71, image: horseriding, name: 'Horse Riding', navigate: 'https://playrevolutiongames.com/horse-riding',  },
        { id: 72, image: archeryavenger, name: 'Archery Avenger', navigate:'https://playrevolutiongames.com/archery-avenger',  },
        { id: 73, image: ballzbalancermarathon, name: 'Ballz Balancer Marathon',navigate:'https://playrevolutiongames.com/ballz-balancer-marathon',  },
        { id: 74, image: bubbleburstbash, name: 'Bubble Burst Bash', navigate: 'https://playrevolutiongames.com/bubble-burst-bash',  },
        { id: 75, image: vegasvictoryslots, name: 'Vegas Victory Slots', navigate: 'https://playrevolutiongames.com/vegas-victory-slots',  },
        { id: 76, image: casinoslotsmaster, name: 'Casino Slots Master', navigate: 'https://playrevolutiongames.com/casino-slots-master',  },
        { id: 77, image: discmasterpro, name: 'Disc Master Pro',navigate:'https://playrevolutiongames.com/disc-master-pro', },
        { id: 78, image: sliceandstrikebowling, name: 'Slice & Strike Bowling', navigate: 'https://playrevolutiongames.com/slice-strike-bowling',  },
        { id: 79, image: ladderLeapaton, name: 'Ladder Leapathon', navigate: 'https://playrevolutiongames.com/ladder-leapathon',  },
        { id: 80, image: casinoslotsymphony, name: 'Casino Slot Symphony', navigate: 'https://playrevolutiongames.com/casino-slot-symphony',  },
        { id: 81, image: skylinediskmaster, name: 'Skyline Disc Master',navigate:'https://playrevolutiongames.com/skyline-disc-master',  },
        { id: 82, image: blockblastexpress, name: 'Block Blast Express', navigate: 'https://playrevolutiongames.com/block-blast-express',  },
        { id: 83, image: woodblockpuzzler, name: 'Wood Blocks Puzzler', navigate: 'https://playrevolutiongames.com/wood-blocks-puzzler',  },
        { id: 84, image: therestaurant, name: 'The Restaurant', navigate: 'https://playrevolutiongames.com/the-restaurant',  },
        { id: 85, image: archeyapex, name: 'Archery Apex', navigate: 'https://playrevolutiongames.com/archery-apex',  },
        { id: 86, image: grandslamtennis, name: 'Grand Slam Tennis', navigate: 'https://playrevolutiongames.com/grand-slam-tennis',  },
        { id: 87, image: skystriker, name: 'Sky Striker',navigate:'https://playrevolutiongames.com/sky-striker',  },
        { id: 88, image: turbotwistracing, name: 'Turbo Twist Racing', navigate: 'https://playrevolutiongames.com/turbo-twist-racing',  },
        { id: 89, image: slidetheball, name: 'Slide The Ball', navigate: 'https://playrevolutiongames.com/slide-the-ball',  },
        { id: 90, image: stickmansoccerstrike, name: 'Stickman Soccer Strike', navigate: 'https://playrevolutiongames.com/stickman-soccer-strike',  },
        { id: 91, image: gemglorygala, name: 'Gem Glory Gala', navigate: 'https://playrevolutiongames.com/gem-glory-gala',  },
        { id: 92, image: fruitninjafury, name: 'Fruit Ninja Fury', navigate: 'https://playrevolutiongames.com/fruit-ninja-fury',  },
        { id: 93, image: zombiestrikeforce, name: 'Zombie Strike Force',navigate:'https://playrevolutiongames.com/zombie-strike-force', },
        { id: 94, image: fairylandprincessmakeover, name: 'Fairy Land Princess Makeover', navigate: 'https://playrevolutiongames.com/fairy-land-princess-makeover',  },
        { id: 95, image: smasherspeedway, name: "Smasher's Speed Way", navigate: 'https://playrevolutiongames.com/smasher-speed-way',  },
        { id: 96, image: xtremerikshawstunt, name: 'Xtreme Rickshaw Stunts', navigate: 'https://playrevolutiongames.com/xtreme-rickshaw-stunts',  },
        { id: 97, image: bikestuntpro, name: 'Bike Stunt Pro', navigate: 'https://playrevolutiongames.com/bike-stunt-pro',  },
        { id: 98, image: unblocktheblock, name: 'Unblock The Block', navigate: 'https://playrevolutiongames.com/unblock-the-block',  },
        { id: 99, image: connectthedotsdeluxe, name: 'Connect The Dots Deluxe', navigate: 'https://playrevolutiongames.com/connect-the-dots-deluxe',  },
        { id: 100, image: rubikcube, name: 'Rubik Cube', navigate: 'https://playrevolutiongames.com/rubik-cube',  },
        { id: 101, image: brainballgala, name: 'Brain Ball Gala', navigate: 'https://playrevolutiongames.com/brain-ball-gala',  },
        { id: 102, image: mathballchallenge, name: 'Math Ball Challenge',navigate:'https://playrevolutiongames.com/math-ball-challenge',  },
        { id: 103, image: golfmania, name: 'Golf Mania', navigate: 'https://playrevolutiongames.com/golf-mania',  },
        { id: 104, image: crosswordcrossover, name: 'Cross Word Cross Over', navigate: 'https://playrevolutiongames.com/cross-word-cross-over',  },
        { id: 105, image: massmastery, name: 'Mass Mastery',navigate:'https://playrevolutiongames.com/mass-mastery',  },
        { id: 106, image: colorropeconnect, name: 'Color Rope Connect', navigate: 'https://playrevolutiongames.com/color-rope-connect',  },
        { id: 107, image: ballpoolpro, name: '8 Ball Pool Pro', navigate: 'https://playrevolutiongames.com/8ball-pool-pro',  },
        { id: 108, image: bowlingzone, name: 'Bowling Zone', navigate: 'https://playrevolutiongames.com/bowling-zone',  },
        { id: 109, image: templerunprincessedition, name: 'Temple Run Princess Edition',navigate:'https://playrevolutiongames.com/temple-run-princess-edition',  },
        { id: 110, image: racingrebels, name: 'Racing Rebels', navigate: 'https://playrevolutiongames.com/racing-rebels',  },
        { id: 111, image: cricketworldseries, name: 'Cricket World Series', navigate:'https://playrevolutiongames.com/cricket-world-series', },
        { id: 112, image: bmxcyclethrillrush, name: 'BMX cycle Thrill Rush',navigate:'https://playrevolutiongames.com/bmx-cycle-thrill-rush', },
        { id: 113, image: highwayroadster, name: 'Highway Roadster', navigate: 'https://playrevolutiongames.com/highway-roadster',  },
        { id: 114, image: birdshuffle, name: 'Bird shuffle', navigate: 'https://playrevolutiongames.com/bird-shuffle',  },
        { id: 115, image: magicball, name: 'Magic Ball', navigate: 'https://playrevolutiongames.com/magic-ball',  },
        { id: 116, image: jewelstarblockpuzzle, name: 'Jewelstar Block Puzzle', navigate: 'https://playrevolutiongames.com/jewelstar-block-puzzle',  },
        { id: 117, image: slideitblock, name: 'Slide-It Block', navigate: 'https://playrevolutiongames.com/slide-it-block',  },
        { id: 118, image: metalringdropper, name: 'Metal Ring Dropper', navigate: 'https://playrevolutiongames.com/metal-ring-dropper',  },
        { id: 119, image: drawlinepuzzledaze, name: 'Draw Line Puzzle Daze', navigate: 'https://playrevolutiongames.com/draw-line-puzzle-daze',  },
        { id: 120, image: ultimatefootballleague, name: 'Ultimate Foot Ball League',navigate:'https://playrevolutiongames.com/ultimate-foot-ball-league', },
        { id: 121, image: guesstheemojiword, name: 'Guess The Emoji Word', navigate: 'https://playrevolutiongames.com/guess-the-emoji-word',  },
        { id: 122, image: ghostlyhunter, name: 'Ghostly Hunter',navigate:'https://playrevolutiongames.com/ghostly-hunter',  },
        { id: 123, image: aquaccolorsortcraze, name: 'Aqua Color Sort Craze', navigate: 'https://playrevolutiongames.com/aqua-color-sort-craze',  },
        { id: 124, image: tiletacticmatch3, name: 'Tile Tactic Match 3', navigate:'https://playrevolutiongames.com/tile-tactic-match3',  },
        { id: 125, image: elevatorrescue, name: 'Elevator Rescue', navigate: 'https://playrevolutiongames.com/elevator-rescue',  },
        { id: 126, image: nutsboltsmanager, name: 'Nuts & Bolts Manager', navigate: 'https://playrevolutiongames.com/nuts-bolts-manager',  },
        { id: 128, image: waterwars, name: 'Water Wars', navigate: 'https://playrevolutiongames.com/water-wars',  },
        { id: 127, image: twistedrails, name: 'Twisted Trails', navigate: 'https://playrevolutiongames.com/twisted-trails',  },
        { id: 129, image: wordseekersafari, name: 'Word Seeker Safari', navigate: 'https://playrevolutiongames.com/word-seeker-safari',  },
        { id: 130, image: cubefusion, name: 'Cube Fusion 2048', navigate: 'https://playrevolutiongames.com/cube-fusion-2048',  },
        { id: 131, image: animalkingdompuzzler, name: 'Animal Kingdom Puzzler', navigate: 'https://playrevolutiongames.com/animal-kingdom-puzzler',  },
        { id: 132, image: runnerella, name: 'Runnerella',navigate:'https://playrevolutiongames.com/runnerella',  },
        { id: 133, image: masterfufighter, name: 'Master Fu Fighter', navigate:'https://playrevolutiongames.com/master-fu-fighter',  },
        { id: 134, image: boatracerfury, name: 'Boat Racer Fury', navigate:'https://playrevolutiongames.com/boat-racer-fury',  },
        { id: 135, image: colorballthrow, name: 'Color Ball Throw', navigate:'https://playrevolutiongames.com/color-ball-throw',  },
        { id: 136, image: fruitfrenzypuzzle, name: 'Fruit Frenzy Puzzle', navigate:'https://playrevolutiongames.com/fruit-frenzy-puzzle',  },
        { id: 137, image: mazeblaster, name: 'Maze Blaster', navigate:'https://playrevolutiongames.com/maze-blaster',  },
        { id: 138, image: goalgalorestadium, name: 'Goal Galore Stadium', navigate:'https://playrevolutiongames.com/goal-galore-stadium',  },
        { id: 139, image: skyherofirefighter, name: 'Sky Hero Fire Fighter', navigate:'https://playrevolutiongames.com/sky-hero-fire-fighter',  },
        { id: 140, image: sketchparking, name: 'Sketch Parking', navigate:'https://playrevolutiongames.com/sketch-parking',  },
        { id: 142, image: wackyracers, name: 'Wacky Racers',navigate:'https://playrevolutiongames.com/wacky-racers',   },
        { id: 143, image: iceballrace, name: 'Iceball Race', navigate:'https://playrevolutiongames.com/iceball-race',   },
        { id: 144, image: stealthstrikeforce, name: 'Stealth Strike Force',navigate:'https://playrevolutiongames.com/stealth-strike-force',  },
        { id: 148, image: shootersstrife, name: "Shooter's Strife",navigate:'https://playrevolutiongames.com/shooter-strife',  },
        { id: 151, image: theoffice, name: 'The Office',navigate:'https://playrevolutiongames.com/the-office',  },
        { id: 152, image: matchup, name: 'Match Up',navigate:'https://playrevolutiongames.com/match-up',  },
        { id: 153, image: kittystretchquest, name: 'Kitty Stretch Quest',navigate:'https://playrevolutiongames.com/kitty-stretch-quest',  },
        { id: 154, image: drawingincomplete, name: 'Drawing Incomplete',navigate:'https://playrevolutiongames.com/drawing-incomplete',  },
        { id: 155, image: tallrunnerdash, name: 'Tall Runner Dash',navigate:'https://playrevolutiongames.com/tall-runner-dash',  },
        { id: 156, image: dogrescue, name: 'Dog Rescue',navigate:'https://playrevolutiongames.com/dog-rescue',  },
        { id: 159, image: sausagesurvivalsaga, name: 'Sausage Survival Saga',navigate:'https://playrevolutiongames.com/sausage-survival-saga',  },
        { id: 161, image: mergeletterfusion, name: 'Merge Letter Fusion',navigate:'https://playrevolutiongames.com/merge-letter-fusion',  },
        { id: 162, image: monstermakeovermagic, name: 'Monster Makeover Magic',navigate:'https://playrevolutiongames.com/monster-makeover-magic',  },
         { id: 163, image: digandguidechallenge, name: 'Dig and Guide Challenge',navigate:'https://playrevolutiongames.com/dig-and-guide-challenge',  },
  
        
        
        //  { id: 201, image: gosurvivorgo, name: 'Go Survivor Go' , isComingSoon: true },
        //  { id: 202, image: hunterhousehero, name: 'Hunter House Hero' , isComingSoon: true},
        //  { id: 203, image: ludolegends, name: 'Ludo Legends', isComingSoon: true },
        //  { id: 204, image: pokertexasholdemtycoon, name: 'Poker Texas Holdem Tyccon' , isComingSoon: true},
        //  { id: 205, image: turbostricksoccer, name: 'Turbo Strike Soccer', isComingSoon: true },
        //  { id: 206, image: snakesandladdersvictory, name: 'Snake And Ladders Victory ', isComingSoon: true },
        //  { id: 207, image: grandchess3d, name: 'Grand Chess 3D', isComingSoon: true },
        //  { id: 208, image: mysticmarbleballs, name: 'Mystic Marble Ball' , isComingSoon: true},
        //  { id: 209, image: ultimatecarstunt, name: 'Ultimate Car Stunt', isComingSoon: true },
        //  { id: 210, image: tennisturbotango, name: 'Tennis Turbo Tango', isComingSoon: true },
        //  { id: 211, image: spidermanunleased, name: 'Spider Man Unleased', isComingSoon: true },
        //  { id: 212, image: rummyroyaleonline, name: 'Rummy Royale Online', isComingSoon: true },
        //  { id: 213, image: stellarstrikers, name: 'Stellar Strikers', isComingSoon: true },
        //  { id: 214, image: shadowstriker, name: 'Shadow Striker', isComingSoon: true },
        //  { id: 215, image: ropetanglechallenge, name: 'Rope Tangle Challenge', isComingSoon: true },
        //  { id: 216, image: flightracerpro, name: 'Flight Racer Pro', isComingSoon: true },
        //  { id: 217, image: blockfilloverload, name: 'Block Fill Overloaded', isComingSoon: true },
        //  { id: 218, image: boxrushexpedition, name: 'Box Rush Expedition', isComingSoon: true },
        //  { id: 219, image: cubestacksprint, name: 'Cube Stack Print', isComingSoon: true },
        //  { id: 220, image: minermergemania, name: 'Miner Merge Mania', isComingSoon: true },
        //  { id: 221, image: ringstacker, name: 'Ring stacker', isComingSoon: true },
        //  { id: 222, image: teamclashrunner, name: 'Team Clash Runner', isComingSoon: true },
        //  { id: 223, image: paintparade, name: 'Paint Parade', isComingSoon: true },
        //  { id: 224, image: gemhexapuzzle, name: 'Gem Hexa Puzzle', isComingSoon: true },
        //  { id: 225, image: boxbalancebattle, name: 'Box Balance Battle', isComingSoon: true },
        //  { id: 201, image: pinthesticker, name: 'Pin The Sticker', isComingSoon: true },
      ]
    },

  ]);
  const navigate = useNavigate();


  const getCategoryColumnClass = (category) => {
    switch (category) {
      case 'Featured games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Top trending games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'New games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Top Categories':
        return 'col-lg-1 col-md-2 col-sm-2 col-2';
      case 'Must play games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Popular games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Casual games for you ':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Fall in love with Sports':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Board games for you':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Casual games for you':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Board games for you':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Non-Stop Action':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Puzzle games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';

      case 'Shooter games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Word games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Rule the arcade':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Simulation games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Racing games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';

      case 'Adventure games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Play the cards':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Gorgeous Casinos':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Educational':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Family':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';

      case 'Music':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Role Playing':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Strategy':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Trivia':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Shooting':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'Beauty':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';
      case 'All games':
        return 'col-lg-2 col-md-2 col-sm-6 col-6';

      default:
        return 'col-2';
    }
  };


  const gameplaypage = (itemName, itemDesc) => {
    let i, j
    for (let i = 0; i < items.length; i++) {
      let val = items[i].items

      let val1 = val.filter((a) => a.name == itemName)
      if (val1.length > 0) {
        navigate(val1[0].navigate, { state: { itemName, itemDesc } });
      }
    }

  }


  const allGames = (category, categoryItems, columnClass) => {
    navigate('/allgaming', { state: { category, categoryItems, columnClass } });
  }

//   const filterItemsByName = (item) => {
//     return item.name.toLowerCase().includes(searchQuery.toLowerCase());
//   };

  return (
    <>
      {items.map((categoryItem, index) => {
    
     const filteredItems = categoryItem.items
    //  .filter(filterItemsByName);

        if (filteredItems.length > 0) {
       return (
            <div key={index}>
              <div className="px-3 customcss">
                <div className="d-flex align-items-between justify-content-between mt-1">
                  {/* <div className={`${categoryItem.length  === 1  ? 'd-flex align-items-between justify-content-between mt-1' : 'd-flex align-items-between justify-content-between mt-4'}`}> */}
                  <div className={`${categoryItem.category === "Other games" ? ' d-block d-sm-block d-md-block d-lg-block' : ' d-none d-sm-none d-md-none d-lg-none'}`} >
                    <h6 className="text-white" style={{ fontWeight: "bold" }}>{categoryItem.category}</h6>
                  </div>

                </div>
              </div>

              <div
                className="  px-3 p-0 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className={`${categoryItem.category === "Top Categories" ? 'inlineblock mt-1 d-none d-sm-none d-md-none d-lg-block' : 'mt-1 inlineblock scrolleds d-block d-sm-block d-md-block d-lg-block'}`} id={categoryItem.category} >
                  <div className="row rowings" >
                    {filteredItems.map((item, innerIndex) => (

                      <div
                        style={{ position: "relative", }}
                        key={innerIndex}
                        // className={`mb-2   ${getCategoryColumnClass(
                        //   categoryItem.category
                        // )}`}
                        className="col-lg-2 col-sm-6 col-md-2 cols cols1 mb-3"
                      >
                        <div style={item.css} >
                            <div

                          className="campus-col thumbnail"
                          style={item.css1}
                        //   onClick={() =>
                        //     gameplaypage(item.name, item.desc, categoryItem.category)
                        //   }

                        >
                            <a href={item.navigate} target="_blank" rel="noopener noreferrer">
                          <img
                            src={item.image}
                            className="images hovering  img-fluid"
                            alt={item.name}
                          />
                          <div style={{ display: categoryItem.category === "Top Categories" ? 'none' : 'block' }}>
                            <div className="layer"></div>
                          </div>
                          </a> 
                        </div>
                          <div style={{ display: categoryItem.category === "Top Categories" ? 'block' : 'none' }}>
                            <span className="text-white" style={{

                            }}>{item.name}</span>

                          </div>
                        </div>


                      </div>
                    ))}

                  </div>
                </div>
              </div>
            </div>

          );
        }

        return null;
      }
       )
   }
    </>
  );
}

export default Othergames;


