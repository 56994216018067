

// import React, { useEffect, useRef, useState } from 'react';

// export default function Advertisement3() {
//     const banner = useRef(null);
//     const [loading, setLoading] = useState(true);

//     const atOptions = {
//         key: '6aa8f65ed3f31af5e54220b6a2b16cf8',
//         format: 'iframe',
//         height: 250,
//         width: 300,
//         params: {},
//     };

//     useEffect(() => {
//         if (banner.current && !banner.current.firstChild) {
//             const conf = document.createElement('script');
//             const script = document.createElement('script');
//             script.async = true;
//             script.type = 'text/javascript';
//             script.src = `//www.topcreativeformat.com/6aa8f65ed3f31af5e54220b6a2b16cf8/invoke.js/${atOptions.key}/invoke.js`;
           
//             conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
//             banner.current.append(conf);
//             banner.current.append(script);

//         }
//     }, [banner, atOptions]);

//     return (
      
//             <div ref={banner} style={{width:"300px", height:"250px"}}></div>
           
//     );
// }




import React, { useEffect, useRef, useState } from 'react';

export default function Advertisement3() {
    const banner = useRef(null);
    const scriptRef = useRef(null);
    const [isScriptInserted, setIsScriptInserted] = useState(false);
    const [scriptLoader, setScriptLoader] = useState(false)

   
    const atOptions = {
        key: '6aa8f65ed3f31af5e54220b6a2b16cf8',
        format: 'iframe',
        height: 250,
        width: 300,
        params: {},
    };

  
    useEffect(() => {
        if (banner.current && !isScriptInserted && !scriptLoader) {
        const scriptElement = document.createElement('script');
            const conf = document.createElement('script');
            scriptElement.async = true;
conf.async = true;
            scriptElement.type = 'text/javascript';
            scriptElement.src = `//www.topcreativeformat.com/6aa8f65ed3f31af5e54220b6a2b16cf8/invoke.js/${atOptions.key}/invoke.js`;
            // scriptElement.setAttribute
            // {
            //     async
            // }
            // assign an onload event handler
            scriptElement.addEventListener('load', (event) => {
                setScriptLoader(true);
        // console.log('app.js file has been loaded');
    });
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
    
            // Check if banner.current still exists before appending children
            if (banner.current) {
                banner.current.appendChild(conf);
                banner.current.appendChild(scriptElement);
            }
    
            //scriptRef.current = scriptElement;
            setIsScriptInserted(true);

        return () => {
            // Cleanup if needed when the component unmounts
            conf.parentNode.removeChild(conf);  
            scriptElement.parentNode.removeChild(scriptElement);
        };
    }
    }, []);
    return (
        <>
            <div ref={banner} style={{width:"300px", height:"250px"}}></div>
            {/* <button onClick={removeScript}>Remove Script</button> */}
        </>
    );
}
