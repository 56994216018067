import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as APIConstants from "../apistore/apiconstants";

// API call
export const shoplist = createAsyncThunk('shop/shoplist', async (payload) => {
    
  const apiURL = APIConstants.APIbaseURLs + APIConstants.shoplistAPI;
  const response = await axios.post(apiURL, payload);
  // console.log(" shoplist responses",response);
  return response.data;
})

// reducer
export const shoplistSlice = createSlice({
  name: 'shop',
  initialState: {
    data: [],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => (
    builder.addCase(shoplist.pending, (state, action) => {
      // console.log("getting1", state.loading, action);
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    }),
    builder.addCase(shoplist.fulfilled, (state, action) => {
      // console.log("getting2", state.loading);
      if (state.loading === 'pending') {
        state.data = action.payload;
        state.loading = 'idle';
      }
    }),
    builder.addCase(shoplist.rejected, (state, action) => {
      // console.log("getting3", state.loading, action);
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = 'Error occured';
      }
    })
  
  ),
});

export default shoplistSlice.reducer;