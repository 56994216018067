// Base URL
// export const APIbaseURL = "http://35.168.47.36:3002/playrevolutiongames/api/v1/";
// export const APIbaseURLs = "http://35.168.47.36:3002/playrevgames/api/v1/player/";
export const APIbaseURLs = "https://gamewebsite.rgserverapi.com:3004/playrevgames/api/v1/player/";



export const googleGetUserInfoAPI = "https://www.googleapis.com/oauth2/v3/userinfo?access_token="
// http://192.168.1.31:3008/playrevgames/api/v1/player/games/increase_coin_balance
//APIExtensions
export const contactAPI = "user/contact_us";
export const registerAPI = "user/user_signup";
export const loginAPI = "user/user_signin";
export const forgotpwdAPI = "user/forgot_password";
export const resetpwdAPI = "user/reset_password";
export const collectcoinAPI = "games/increase_coin_balance";
export const shoplistAPI = "user/get_all_shop_item";
export const leaderboardAPI = "games/get_leaderboard_userV1";
export const getprofileAPI = "games/get_my_profile";
export const updateprofileAPI = "user/edit_profile";
export const updatelevelAPI = "games/update_user_game_level";
export const getDashboardAPI = "games/get_dashboard_details";
export const getlikeanddislikeAPI = "games/update_user_game_reaction";
export const getgamedetailsAPI = "games/get_game_details";
export const FeedbackAPI = "games/user_feedback";

export const videoLinks = [
    { game_name: "Grate Blitz", game_id: "1", video_link: "https://youtu.be/9gH_lRXc3VE" },
    { game_name: "Paint Ball Party", game_id: "2", video_link: "https://youtu.be/ber5p0KlJRM" },
    { game_name: "Hole Filler Pro", game_id: "3", video_link: "https://youtu.be/pvJ9CtLLH14" },
    { game_name: "Cube Craze", game_id: "4", video_link: "https://youtu.be/pYUgRfUcr48" },
    { game_name: "Rings Of Fire", game_id: "5", video_link: "https://youtu.be/KYT5Xr9WKr8" },
    { game_name: "Catwalk Clash", game_id: "7", video_link: "https://youtu.be/-SHaIbBz0IY" },
    { game_name: "Pacmania", game_id: "15", video_link: "https://youtu.be/nVLuTV2GJ6g" },
    { game_name: "The Ultimate Imposter", game_id: "11", video_link: "https://youtu.be/xFFlkPPnxgY" },
    { game_name: "Parling Pro Xtreme", game_id: "12", video_link: "https://youtu.be/G_laZvQPOYY" },
]
