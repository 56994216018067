import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom'
import './index.css';
import reportWebVitals from './reportWebVitals';
import './stylecss/styles.css'
import AppRouter from './approuter';
import { Provider } from 'react-redux';
import { store } from './apistore/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <AppRouter />
    </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals( ))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// ReactDOM.unmountComponentAtNode(root);
// root.unmount();
reportWebVitals();
