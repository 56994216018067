import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Advertisement_160X600 from "../advertisement/advertisement1";

const Description = () => {

    const navigate = useNavigate();


    return (

        <>
            <div className="row">
                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                <div className="placeholder" style={{ paddingBottom: '0%' }}>
      </div>
                    <div className="text-white  stylestext px-lg-4 mx-lg-4 px-sm-2 mx-sm-2  mx-md-4 px-md-4 px-2 mx-2  py-3" >
                        <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "600" }}>Word Hunter's Challenge</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>"Word Hunter's Challenge" is an exciting word game that will put your vocabulary and puzzle-solving skills to the test!</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>In this thrilling challenge, players embark on a quest to hunt down hidden words within a grid of letters. As a word hunter, your task is to scour the grid and locate all the words hidden within it. These words can be arranged horizontally, vertically, diagonally, and even backwards!</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>With each word you find, you'll earn points and advance to the next level of difficulty. But beware - the grid becomes more challenging as you progress, with longer words and trickier placements to test your skills.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>"Word Hunter's Challenge" offers endless hours of entertainment for players of all ages. Whether you're a seasoned word enthusiast or just looking for a fun way to sharpen your mind, this game is sure to keep you engaged and entertained.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>So, are you ready to embark on the ultimate word-hunting adventure? Put your skills to the test and see if you have what it takes to conquer the "Word Hunter's Challenge"!</h6>
                <h6 style={{ fontSize: "18px", }} className="fontweight mb-3">How To Play?</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">"Hunt the words  in <b>Word Hunter's Challenge"</b></h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">1. Click the <b>Play</b> button to navigate to the categories section.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">2. In the <b>categories</b> section, select the desired category by tapping on it.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">3. Once you've chosen a category, tap on a specific <b>level</b> to begin gameplay.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">4. In each level, you'll see a <b>list of words</b> at the top of the screen. Your task is to find these words within the gameplay.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">5. After finding all the required words, you'll proceed to the <b>next level</b> automatically.</h6>
                <iframe className="utubesize" src="https://www.youtube.com/embed/LKEHHrrufxc" frameBorder="0" allowFullScreen></iframe>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Controls</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>DESKTOP : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 1. Use the <b>left mouse button</b> to click and drag the letters to combine them.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>MOBILE : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 1. <b>Tap</b> and <b>drag</b> on the letters to combine the letters </h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Category</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Educational, Word Puzzle</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Tags</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Word game, Vocabulary challenge, Puzzle-solving skills, Exciting gameplay, Hidden words, Grid of letters, Quest, Word hunter, Scouring the grid, Horizontal words, Vertical words, Diagonal words, Backwards words, Points system, Increasing difficulty, Endless entertainment, All ages, Word enthusiast, Mind sharpening, Engaging gameplay, Ultimate word-hunting adventure.</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Release Date</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Updates</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Build Number : 011</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Developer</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Revolution Games Private Limited</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Platforms</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Web browser (desktop and mobile)</h6>

                    </div>
                </div>
  

                <div className=" col-lg-2 d-none d-sm-none d-md-none d-lg-block mt-5 " style={{ border: "1px solid black", height: "600px" }}>
                    <div className="d-flex align-items-center justify-content-center me-4">
                        <Advertisement_160X600 />
                    </div>

                </div>
            </div>
                          


        </>

    )
}

export default Description;