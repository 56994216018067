import React, { useState, useRef, useEffect } from "react";
import '../App.css';
import rgnamelogos from '../assests/images/rginamelogowhite.webp'
import appicon from '../assests/images/whcappicon.png'
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi'

const HeaderPortion = () => {

    return (

        <>


    <div className=" " style={{ height: "64px" }}>
             <div className="row m-0 " >
                    <div className="col-lg-2 col-sm-2 col-md-4 col-2 ps-lg-5 ps-sm-3 ps-md-3 ps-3">
                        <div className="d-flex align-items-start justify-content-start" >
                            <img src={rgnamelogos} alt="Revolution Games"  style={{ objectFit: "fill", width: "120px", height: "80px", cursor: "pointer" }} />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-8 col-sm-10 col-10 pe-3   ">
                        <div className="d-flex flex-row align-items-end justify-content-end " >
                            <h4 className="text-white mb-3 d-none d-lg-block d-sm-block d-md-block" style={{ fontWeight: "bold" }}>Word Hunter's Challenge</h4>&nbsp;&nbsp;
                            <img src={appicon} className="mt-1" style={{ objectFit: "fill", width: "55px", height: "55px", cursor: "pointer", borderRadius: "8px" }} />

                        </div>
                    </div>
                    <div className="col-lg-5  col-sm-0 col-md-0 col-0 pe-5  pb-1  pt-3  d-none d-lg-block d-sm-none d-md-none">
                        <div className="d-flex align-items-end justify-content-end">
                            <h4 className="text-white" style={{ fontWeight: "bold" }}>Revolution Games</h4>
                        </div>
                    </div>
                
                </div >
            </div>
           
        </>

    )
}

export default HeaderPortion;