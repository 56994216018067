import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import HeaderPortion from "./headerlogo";
import Banner from "./bannersetup";
import Description from "./description";
import FooterPortion from "./footersection";
import Othergames from "../gamescreen/othergames";
import bannerweb from '../assests/images/webwordhunterbanner.webp'
import bannermobile from '../assests/images/mobilewhcbanner.jpg'
import playnows from '../assests/images/blueplaybtn.png'


const JoinCode = ({ onButtonClick }) => {

    const containerRef = useRef(null);


    useEffect(() => {
        window.addEventListener('load', async function (event) {
            if (containerRef.current) {
                containerRef.current.scrollIntoView({ behavior: "smooth" });
            }
        });
    }, []);


    
    return (

        <>

            <div className="App-header">
                <div ref={containerRef} />
                <div className="headerbackground" style={{ background: "#222222" }}>
                    <HeaderPortion />
                </div>
                <div style={{ backgroundColor: "black" }}>
                    <div className="d-none d-sm-none d-md-none d-lg-block">
                        <div style={{ background: "black", position: "relative" }} >
                            <div className="slider-container">
                            <link rel="preload" fetchpriority="high" as="image" href={bannerweb} type="image/webp"></link>
                            <img fetchpriority="high" src={bannerweb} alt="bannerwebwhc" className="sliderimg" ></img>
                                {/* <img src={bannerweb} alt="bannerwebwhc" className="sliderimg" /> */}
                            </div>
                            <div style={{ position: "absolute", left: "18%", bottom: "2%" , width: "198px", height: "48px" }}>
                            <img src={playnows} alt="playnow" width="100%" height="100%" style={{ cursor: "pointer" }} onClick={onButtonClick} />                    </div>
                        </div>
                    </div>


                    <div className="d-block d-sm-block d-md-block d-lg-none">
                        <div style={{ background: "black" }} >
                            <div className="slider-container">
                            <link rel="preload" fetchpriority="high" as="image" href={bannerweb} type="image/webp"></link>
                            <img fetchpriority="high" src={bannerweb} alt="bannerwebwhc" className="sliderimg" ></img>
                                {/* <img src={bannermobile} alt="bannerwebwhc" className="sliderimg" /> */}

                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-2"  style={{ transform: 'translate(0, 0)' }}>
                                <img src={playnows} alt="playnow" width="142px" height="34px" style={{ cursor: "pointer" }} onClick={onButtonClick} />
                            </div>
                        </div>
                    </div>
                </div>
                    <Description />

                <div style={{ backgroundColor: "black" }}  >
                    <Othergames />
                </div>
                <div  >
                    <FooterPortion />
                </div>

            </div>

        </>

    )
}

export default JoinCode;