import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as APIConstants from "../apistore/apiconstants";

// API call
export const gameregister = createAsyncThunk('register/gameregister', async (payload) => {
    
  const apiURL = APIConstants.APIbaseURLs + APIConstants.registerAPI;
  const response = await axios.post(apiURL, payload);
  // console.log("game register responses",response);
  return response.data;
})


export const gamelogin = createAsyncThunk('register/gamelogin', async (payload) => {
    
    const apiURL = APIConstants.APIbaseURLs + APIConstants.loginAPI;
    const response = await axios.post(apiURL, payload);
    // console.log("game login responses",response);
    return response.data;
  })


  export const googleGetUserInfo = createAsyncThunk('register/googleGetUserInfo', async (payload) => {
    let apiURL = APIConstants.googleGetUserInfoAPI+payload;
    const response = await axios.get(apiURL);
    // console.log(response);
    return response.data;
  })

  export const logOut = createAsyncThunk('register/logOut', async () => {
    
    let data = {}
    return data;
  })
  
// reducer
export const registerSlice = createSlice({
  name: 'register',
  initialState: {
    data: [],
    loading: 'idle',
    googleInfoData: {},
    logindata: [],
    loginloading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => (
    builder.addCase(gameregister.pending, (state, action) => {
      // console.log("getting1", state.loading, action);
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    }),
    builder.addCase(gameregister.fulfilled, (state, action) => {
      // console.log("getting2", state.loading);
      if (state.loading === 'pending') {
        state.data = action.payload;
        state.loading = 'idle';
      }
    }),
    builder.addCase(gameregister.rejected, (state, action) => {
      // console.log("getting3", state.loading, action);
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = 'Error occured';

      }
    }),

    builder.addCase(gamelogin.pending, (state, action) => {
        // console.log("getting1", state.loginloading, action);
        if (state.loginloading === 'idle') {
          state.loginloading = 'pending';
        }
      }),
      builder.addCase(gamelogin.fulfilled, (state, action) => {
        // console.log("getting2", state.loginloading);
        if (state.loginloading === 'pending') {
          state.logindata = action.payload;
          state.loginloading = 'idle';
        }
      }),
      builder.addCase(gamelogin.rejected, (state, action) => {
        // console.log("getting3", state.loginloading, action);
        if (state.loginloading === 'pending') {
          state.loginloading = 'idle';
          state.error = 'Error occured';

        }
      }), 
      builder.addCase(googleGetUserInfo.pending, (state, action) => {
        // console.log("googlelogin1",state.loading);
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      }),
      builder.addCase(googleGetUserInfo.fulfilled, (state, action) => {
        // console.log("googlelogin2",state.loading);
        if (state.loading === 'pending') {
          state.googleInfoData = action.payload;
          state.loading = 'idle';
        }
      }),
      builder.addCase(googleGetUserInfo.rejected, (state, action) => {
        // console.log("googlelogin3",state.loading);
        if (state.loading === 'pending') {
          state.loading = 'idle';
          state.error = 'Error occured';
        }
      }),
      builder.addCase(logOut.pending, (state, action) => {
        // console.log("clear login",state.loading);
        if (state.loading === 'idle') {
          state.logindata = [];
          state.logindata = 'Error occured';
          state.googleInfoData = {};
          state.loading = 'idle';
          state.error = null;
        }
      })
  
  ),
});

export default registerSlice.reducer;