import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as APIConstants from "../apistore/apiconstants";
import { MdDashboard } from 'react-icons/md';

// API call
// get game details
export const Getgame_details = createAsyncThunk('dashboard/Getgame_details', async (payload) => {
  
  const apiURL = APIConstants.APIbaseURLs + APIConstants.getgamedetailsAPI;
  const response = await axios.post(apiURL, payload);
  // console.log(" get game details responses", response);
  return response.data;
})
export const ClearGetgame_details = createAsyncThunk('dashboard/ClearGetgame_details', async (payload) => {
  return {}
})
export const ClearFeedback = createAsyncThunk('dashboard/ClearFeedback', async (payload) => {
  return {}
})
export const getdashboard = createAsyncThunk('dashboard/getdashboard', async (payload) => {
  
  const apiURL = APIConstants.APIbaseURLs + APIConstants.getDashboardAPI;
  const response = await axios.post(apiURL, payload);
  // console.log(" get dashboard responses", response);
  return response.data;
})
export const getlikeanddislike = createAsyncThunk('dashboard/getlikeanddislike', async (payload) => {
  
  const apiURL = APIConstants.APIbaseURLs + APIConstants.getlikeanddislikeAPI;
  const response = await axios.post(apiURL, payload);
  // console.log(" get getlikeanddislike responses", response);
  return response.data;
})
export const SendFeedback = createAsyncThunk('dashboard/Feedback', async (payload) => {
  
  const apiURL = APIConstants.APIbaseURLs + APIConstants.FeedbackAPI;
  const response = await axios.post(apiURL, payload);
  console.log(" get feedback responses", response);
  return response.data;
})
// reducer
export const getdashboardlistSlice = createSlice({
  name: 'dashboard',
  initialState: {
    likedata: [],
    likeloading: 'idle',
    data: [],
    loading: 'idle',
    feedbackdata: [],
    feedbackloading: 'idle',
    updatedata: [],
    updateloading: 'idle',
    gamedata: [],
    gameloading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => (
    builder.addCase(ClearGetgame_details.pending, (state, action) => {
      
      // console.log("getting1", state.loading, action);
      if (state.loading === 'idle') {
        state.gamedata = [];
      }
    }),
    builder.addCase(ClearFeedback.pending, (state, action) => {
      
      // console.log("getting1", state.feedbackloading, action);
      if (state.feedbackloading === 'idle') {
        state.feedbackdata = [];
      }
    }),
    builder.addCase(SendFeedback.pending, (state, action) => {
      // console.log("getting1", state.feedbackloading, action);
      if (state.feedbackloading === 'idle') {
        state.feedbackloading = 'pending';
      }
    }),
    builder.addCase(SendFeedback.fulfilled, (state, action) => {
      
      // console.log("getting2", state.feedbackloading);
      if (state.feedbackloading === 'pending') {
        state.feedbackdata = action.payload;
        state.feedbackloading = 'idle';
      }
    }),
    builder.addCase(SendFeedback.rejected, (state, action) => {
      // console.log("getting3", state.feedbackloading, action);
      if (state.feedbackloading === 'pending') {
        state.feedbackloading = 'idle';
        state.error = 'Error occured';
      }
    }),
    builder.addCase(getdashboard.pending, (state, action) => {
      // console.log("getting1", state.loading, action);
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    }),
    builder.addCase(getdashboard.fulfilled, (state, action) => {
      
      // console.log("getting2", state.loading);
      if (state.loading === 'pending') {
        state.data = action.payload;
        state.loading = 'idle';
      }
    }),
    builder.addCase(getdashboard.rejected, (state, action) => {
      // console.log("getting3", state.loading, action);
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = 'Error occured';
      }
    }),
    builder.addCase(Getgame_details.pending, (state, action) => {
      // console.log("getting1", state.gameloading, action);
      if (state.gameloading === 'idle') {
        state.gameloading = 'pending';
      }
    }),
    builder.addCase(Getgame_details.fulfilled, (state, action) => {
      
      // console.log("getting2", state.gameloading);
      if (state.gameloading === 'pending') {
        state.gamedata = action.payload;
        state.gameloading = 'idle';
      }
    }),
    builder.addCase(Getgame_details.rejected, (state, action) => {
      // console.log("getting3", state.gameloading, action);
      if (state.gameloading === 'pending') {
        state.gameloading = 'idle';
        state.error = 'Error occured';
      }
    }),
    builder.addCase(getlikeanddislike.pending, (state, action) => {
      // console.log("getting1", state.likeloading, action);
      if (state.likeloading === 'idle') {
        state.likeloading = 'pending';
      }
    }),
    builder.addCase(getlikeanddislike.fulfilled, (state, action) => {
      
      // console.log("getting2", state.likeloading);
      if (state.likeloading === 'pending') {
        state.likedata = action.payload;
        state.likeloading = 'idle';
      }
    }),
    builder.addCase(getlikeanddislike.rejected, (state, action) => {
      // console.log("getting3", state.likeloading, action);
      if (state.likeloading === 'pending') {
        state.likeloading = 'idle';
        state.error = 'Error occured';
      }
    })
  ),
});

export default getdashboardlistSlice.reducer;