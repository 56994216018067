 

import React, { useState, useEffect, useRef, useCallback, lazy, Suspense } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import FooterPortion from "../headerfooter/footersection";
import { useLocation, useNavigate } from 'react-router-dom';
import { MdScreenRotation } from "react-icons/md";
import BottomListGame from "./othergames";
import rgnamelogos from '../assests/images/rginamelogowhite.webp';
import close from '../assests/images/closebutton.webp';
import Addadds from "../advertisement/Adsanotherone";
import BottomListGameLeft from "./othergamesleft";
import wordhunterchallenge from '../assests/images/0019_wordhunterchallenge.webp'
import { BiExitFullscreen } from 'react-icons/bi'
import BottomListGameRight from "./Othergamesright";
import continueyellow2 from '../assests/images/continueyellow2.webp';
import Advertisement3 from "../advertisement/advertisement3";
// import secureLocalStorage from "react-secure-storage";
import { connect, useDispatch, useSelector } from 'react-redux'
import { getAnalytics, logEvent } from "firebase/analytics";
import BottomMenu from "./commonmenu";
import rgilogo from "../assests/images/rginamelogowhite.webp"
import rgblacklogo from '../assests/images/rglogo.webp'
import { SlArrowLeft } from "react-icons/sl";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
const StartingSplashDiv = lazy(() => import('./startingsplashall'));

const WordHuntersChallenge = ({ onButtonClick }) => {

  const { unityProvider, isLoaded, sendMessage, addEventListener, removeEventListener, unload, loadingProgression } = useUnityContext({
    loaderUrl: "/0019_WordHunter'sChallenge/0019_WordHunter'sChallenge.loader.js",
    dataUrl: "/0019_WordHunter'sChallenge/0019_WordHunter'sChallenge.data",
    frameworkUrl: "/0019_WordHunter'sChallenge/0019_WordHunter'sChallenge.framework.js",
    codeUrl: "/0019_WordHunter'sChallenge/0019_WordHunter'sChallenge.wasm",
  });

  function sendmessagetounity1() {
    sendMessage("GameManager", "PlayNextLevelMessageFromWeb", "Next Level");
  }

  const [isGameOver, setIsGameOver] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isButtonRed, setButtonRed] = useState(false);
  const [showRotateYourScreen, setShowRotateYourScreen] = useState(false)
  const [delayad, setDelayad] = useState(false)
  const coincollecting = useSelector((state) => state.coins)
  const dispatch = useDispatch()
  const [levelNo, setLevelNumber] = useState("");
  const [delayadvertisement, setDelayadvertisement] = useState(true)
  // const userlogin = secureLocalStorage.getItem('userlogin') === 'true';
  const [finishStatus, setfinishStatus] = useState(false);
  const [videoVisible, setVideoVisible] = useState(true);
  const [showplaybutton, setShowplaybutton] = useState(true);
  const [loadingProgressionValue, setLoadingProgressionValue] = useState(0);
  const [loadingProgressionLoadedFirst90, setLoadingProgressionLoadedFirst90] = useState(false);
  const [loadingRandomText, setLoadingRandomText] = useState('Loading Assets...');
  const [showButtons, setShowButtons] = useState(false);
  const [buttonCounter, setButtonCounter] = useState(5);
  const containerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [showsplash, setShowsplash] = useState(true);
  const [percent, setPercent] = useState(10);

  function sendmessagetounity() {
    sendMessage("ResponseFromWeb", "MyFunction", "Hi from web");
  }
  const gohomescreen = () => {
    //  await unload();
    sendmessagetounity()
    setTimeout(() => {
      onButtonClick()
    }, 1000);
  }



  const handleButtonClicks = () => {
    setDelayadvertisement(false)
    setShowButtons(true);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "instant" });
    }
  }, []);

  const openFullscreen = () => {
    const elem = document.getElementById('fullscreenButton');
    if (elem !== null) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozExitFullscreen) {
      document.mozExitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
      const isAndroid = /Android/.test(navigator.userAgent);
      if (!!document.fullscreenElement === false && isAndroid) {
        setChangeVisibility(true)
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);


  const numericPart = levelNo.match(/\d+/);
  const levelValue = numericPart ? numericPart[0] : "";
  const analytics = getAnalytics();
  const Advertisementafterplay = () => {

    logEvent(analytics, "Word Hunter's Challenge Level_" + levelValue);
    sendmessagetounity1()
    setIsGameOver(false)
    setRefreshAdd(true)
  }

  const handleGameOver = useCallback((message, gameId, levelNo) => {
    setIsGameOver(true);
    setLevelNumber(levelNo)
    setDelayad(true);

  }, []);

  const handleButtonClick = () => {
    // console.log("handleButtonClick clicked");
    setVideoVisible(false)
    setShowButton(true);
  };
  useEffect(() => {
    if (showButton && isLoaded) {
      setButtonRed((prevValue) => !prevValue);
    }
  }, [showButton, isLoaded]);

  useEffect(() => {
    if (refreshAdd == true) {
      setTimeout(() => {
        setRefreshAdd(false)
      }, 2000);
    }
  }, [refreshAdd])



  useEffect(() => {
    addEventListener("LevelOver", handleGameOver);
    return () => {
      removeEventListener("LevelOver", handleGameOver);
    };
  }, [addEventListener, removeEventListener, handleGameOver]);


  const handlePlayNowClick = () => {
    setShowsplash(false);
    setButtonCounter(5);

    const interval = setInterval(() => {

      setButtonCounter((prevCounter) => {
        if (prevCounter > 1) {
          return prevCounter - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };

  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("If you go back, You will lose your game progress.")) {
        setfinishStatus(true)
      } else {
        // window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false)
      }
    }
  }

  useEffect(() => {

    window.scrollTo(0, 0);
    if (containerRef.current) {

      containerRef.current.scrollIntoView({ behavior: "instant" });
    }
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      // video.removeEventListener("ended", handleVideoEnded);
    };
  }, []);


  useEffect(() => {
    if (finishStatus) {
      gohomescreen()
    }
    else {
      window.history.pushState(null, null, window.location.pathname);
    }
  }, [finishStatus, unload])

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {

      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = "Word Hunter's Challenge - Play on Revolution Games";
  }, []);

  const mobilescreenfullview = () => {
    handleOrientationChange()

    setShowplaybutton(false)
    setDelayadvertisement(true)

    setButtonCounter(5);

    const interval = setInterval(() => {

      setButtonCounter((prevCounter) => {
        if (prevCounter > 1) {
          return prevCounter - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);


    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      clearInterval(interval);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };

  }


  const handleOrientationChange = useCallback((event) => {
    if (event) {
      // Prevent the default behavior to avoid page refresh
      event.preventDefault();
    }
    const isPortrait = window.orientation === 0 || window.orientation === 180;
    setShowRotateYourScreen(!isPortrait)

  }, []);

  useEffect(() => {
    setDelayadvertisement(true)
  }, [])

  useEffect(() => {
    if (showplaybutton === false || delayadvertisement === true || showButtons === true) {
      openFullscreen()
    }
  }, [showplaybutton, delayadvertisement, showButtons])

  const [isAndroidunity, setIsAndroidUnity] = useState(false)
  const [isIphoneUnity, setIsIphoneUnity] = useState(false)
  const [isIpadUnity, setIsPadUnity] = useState(false)


  useEffect(() => {
    const isIpad = /iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsPadUnity(isIpad)
    const isTouchDesktop = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0 && !(/iPad/.test(navigator.platform));

    const isIphone = /iPhone/.test(navigator.userAgent);
    setIsIphoneUnity(isIphone)
    const isAndroid = /Android/.test(navigator.userAgent);
    setIsAndroidUnity(isAndroid)
    if (isIpad || isIphone || isAndroid) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, []);

  const backNavigation = () => {
    gohomescreen()
  }


  const [divHeight, setDivHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.getBoundingClientRect().height;
      setDivHeight(height);
    }
  }, [contentRef]);


  const [changevisibility, setChangeVisibility] = useState(false);

  useEffect(() => {
    if (showRotateYourScreen === true || changevisibility === true) {
      pauseMessage()
    }
    else {
      unpauseMessage()
    }
  }, [showRotateYourScreen, sendMessage, changevisibility])


  const handleResumeButtonClick = () => {

    unpauseMessage()
    setChangeVisibility(false)
    if (isAndroidunity) {
      openFullscreen()
    }
  }


  function pauseMessage() {
    sendMessage("ResponseFromWeb", "PauseFunction", "Hi from web");
  }

  function unpauseMessage() {
    sendMessage("ResponseFromWeb", "UnPauseFunction", "Hi from web");
  }
  const [progression, setProgression] = useState(0);

  // useEffect(() => {
  //   let intervalId;

  //   // Check if loadingProgression is greater than or equal to 90%
  //   if (loadingProgression >= 0.9) {
  //     // Set progression to 90
  //     setProgression(90);

  //     // Schedule the manual progression increase every 100 milliseconds
  //     intervalId = setInterval(() => {
  //       // Increment progression beyond 90 until 100%
  //       if (progression < 101) {
  //         setProgression(prevProgress => Math.min(prevProgress + 1, 101));
  //       }
  //     }, 100); // 10 milliseconds interval for smoother transition
  //   } else {
  //     // If loadingProgression is less than 90%, calculate normally
  //     setProgression(Math.round(loadingProgression * 100));
  //   }

  //   return () => clearInterval(intervalId); // Cleanup interval on unmount or update
  // }, [loadingProgression]);

  {/* Sidhu Modified Progress Bar - 22.04.2024 */ }

  useEffect(() => {
    if (showButton || showButtons) {
      if (loadingProgression > 0 && Math.round(loadingProgression * 100) < 90 && isLoaded === false && loadingProgressionLoadedFirst90) {
        setLoadingProgressionValue(Math.round(loadingProgression * 100))
      } else if (isLoaded) {
        setLoadingProgressionValue(100);
      } else {
        if (isLoaded === false && !loadingProgressionLoadedFirst90) {
          setLoadingProgressionLoadedFirst90(true)
        }
      }
      if (loadingProgressionValue >= 80 && !isLoaded) {
        const interval = setInterval(() => {
          setLoadingProgressionValue((prevPercent) => {
            const newPercent = Math.min(prevPercent + 3, 100);
            return newPercent;
          });
          //updateLoadingRandomText();
        }, 300); // Update text every second for smoother transition

        return () => clearInterval(interval);
      }
    }
  }, [showButton, showButtons, isLoaded, loadingProgression, loadingProgressionValue, loadingProgressionLoadedFirst90, percent])

  return (
    <>
      <div className="App-header">
        <div ref={containerRef} />

        <div className="headerbackground " style={{ background: "#222222", display: (showplaybutton === false && isMobile === true) && "none" }} >
          <div className=" " style={{ height: "48px" }}>

            <div className="row m-0 " >
              <div className="col-lg-4 col-sm-4 col-md-4 col-5 ps-lg-5 ps-sm-3 ps-md-3 ps-3" >
                <div className="d-flex align-items-start justify-content-start" style={{ cursor: "pointer" }} onClick={gohomescreen} >
                  <img src={rgnamelogos} alt="Revolution Games" style={{ objectFit: "fill", width: "100px", height: "55px" }} />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mt-2 pb-1 pt-0 d-none d-lg-block d-sm-none d-md-block">

                <div className="d-flex align-items-center justify-content-center">
                  <h4 className="text-white" style={{ fontWeight: "bold" }}>Word Hunter's Challenge</h4>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-8 col-7 mt-1 pe-3  pb-1 pt-1">
                <div className="d-flex align-items-end justify-content-end">

                  <img src={close} alt="Close" style={{ objectFit: "fill", width: "35px" }} onClick={gohomescreen} />
                </div>
              </div>
            </div >
          </div>
        </div>
        <div className={`${(showplaybutton === false) ? "px-lg-0 px-sm-0 px-md-0 px-0 py-0" : "px-lg-0 px-sm-0 px-md-0 px-0 py-0 "}`}>

          <div className="d-flex flex-row col-lg-12 col-sm-12 col-md-12">

            {/* <div className="" style={{ border: "1px solid black", display: (showplaybutton === false && isMobile === true) && "none", width: isMobile === true ? "0%" : "16.6%" }}>

              <div className={`${isMobile === true ? "px-0 mt-2" : "px-2 mt-2"}} }`}  >
                <BottomListGameLeft searchQuery={searchQuery} sendMessageToUnity={sendmessagetounity} excludeIds="16" height={divHeight} />
              </div>
            </div> */}
            <div ref={contentRef} style={{ width: isMobile === true ? "100%" : "100%" }} >
              {!isMobile && (
                <div id={(showButton === true && isLoaded) ? "fullscreenButton" : undefined} className={`${isLoaded === false ? 'd-flex align-items-center justify-content-center mt-50' : 'roughimageword mb-75 d-flex align-items-center justify-content-center mt-50'}`} style={{ background: "#222222", position: "relative" ,width:isLoaded === false && "100w", height:isLoaded === false ? "90vh" : "92vh" }}>



                  {videoVisible && (
                    <>

                      <div style={{
                        width: '100%', height: '100%', position: "relative"
                      }} className="paddingads  "  >
                        <div className="d-flex flexing align-items-center justify-content-center"   >

                          {delayadvertisement && (
                            <>
                              <h6 className="text-white " style={{ fontWeight: "bold" }}>Advertisement</h6>
                              <div className="" style={{ zIndex: (showsplash === false) ? "1" : "0" }} >

                                <Advertisement3 />
                              </div>
                            </>)}
                          {delayadvertisement && (
                            <>
                              {buttonCounter > 0 ? <div className="mt-1"> <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounter}</p></div>
                                :
                                <>
                                  <div className="mt-1" style={{ textAlign: "center", zIndex: (showsplash === false) ? "1" : "0", cursor: "pointer" }} >
                                    <img src={continueyellow2} alt="playbutton" onClick={handleButtonClick} className="buttonyellowcontinue" />
                                  </div>

                                </>
                              }
                            </>)}
                        </div>


                        <div style={{ position: "absolute", width: '100%', height: '100%', top: "0%" }}>

                          {showsplash && (
                            <>
                              <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: '100%', height: '100%', backgroundColor: "#222222" }} >

                                <Suspense fallback={<img src={rgilogo} style={{ width: "120px", height: "80px" }} />}>

                                  <StartingSplashDiv gameId="16" gameImage={wordhunterchallenge} gameName="Word Hunter's Challenge" onPlayNowClick={handlePlayNowClick} />
                                </Suspense>
                              </div>

                            </>
                          )}
                        </div>
                      </div></>
                  )}

                  {showButton && (
                    <>
                      <div className="" style={{ width: isLoaded == false ? "100%" : "25% ", height: isLoaded == false ? "92vh" : "92vh", textAlign: "center", paddingTop: isLoaded === false ? "10%" : "0%", paddingBottom: isLoaded === false ? "10%" : "0%",overflow:"hidden" }}>
                        {isLoaded == false && (
                          <>
                            <div>
                              <img src={wordhunterchallenge} className="imagesplash mb-2" />
                              <div>
                                <span className="text-white fw-bold mb-2" style={{ fontSize: "24px" }}>Word Hunter's Challenge</span>

                              </div>


                              {/* Sidhu Modified Web Progress Bar - 22.04.2024 */}

                              <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                            {loadingProgression == 0 && (
                              <div className="demo-container mt-2">
                                <div className="progress-bar-style rounded">
                                  <div className="progress-bar-value"></div>
                                </div>
                              </div>
                            )}
                            {loadingProgression > 0 && (
                              <div className="mt-2" >
                                < Progress
                                  status="active"
                                  theme={{ active: { color: '#f1c70a' } }}
                                  percent={(loadingProgressionValue * 4.5) < 100 ? (loadingProgressionValue * 4.5) : 100} />
                              </div>
                            )}
                          </div>
                          {(loadingProgressionValue * 4) < 101 ? (
                            <p className="text-white">
                              Please wait. Building Your Gaming World!... {(loadingProgressionValue * 4.5) < 100 ? (loadingProgressionValue * 4.5) : 100}%
                            </p>
                          ) : (
                            <p className="text-white">
                              Starting the Game...
                            </p>
                          )}

                            </div>
                          </>
                        )}

                        <div style={{ aspectRatio: "9/16", width: "100%", height: "100%" }} >
                          <Unity
                            style={{
                              width: "100%",
                              height: "100%",
                              unitySelf: "center",
                              alignSelf: "center",
                              // marginTop: "12px"
                              paddingBottom: "3px",
                              paddingTop: "3px"
                            }}
                            unityProvider={unityProvider}
                          />
                          <div style={delayad && isGameOver ? { position: "absolute", top: "0px", width: "100%", height: "100%", left: "0%" } : { position: "absolute", top: "-10000000px" }}>
                            <div style={{ position: "relative" }}>
                              <img src={wordhunterchallenge} style={{ width: "100%", height: isFullscreen ? windowHeight : "100%", opacity: "0.9" }} />
                              <div style={{ position: "absolute", bottom: "0%", right: "0%", paddingBottom: "10%", paddingTop: "10%", background: "#222222", opacity: "0.9", width: "100%", height: "100%" }}>
                                {/* <div style={{ position: "relative" }}> */}
                                <div className="d-flex flex-column align-items-center justify-content-center" style={{ position: "absolute", margin: "auto", left: 0, right: 0, top: 0, bottom: 0 }}>
                                  <h6 className="text-white" style={{ fontWeight: "bold" }}>Advertisement</h6>


                                  {!refreshAdd && (<div id="advertisemented" >
                                    <Advertisement3 />

                                  </div>)}



                                  {/* {buttonCounters > 0 ? (
                                    <div className="mt-1">
                                      <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounters}</p>
                                    </div>
                                  ) : ( */}
                                  <div className=" mt-1" style={{ textAlign: "center", cursor: "pointer" }}>
                                    <img src={continueyellow2} alt="playbutton" onClick={Advertisementafterplay} className="buttonyellowcontinue" />
                                  </div>
                                  {/* )} */}
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                          {isLoaded && (
                            <>
                              {isFullscreen && (
                                <BiExitFullscreen onClick={closeFullscreen} style={{ position: "absolute", bottom: "7%", right: "2%", width: "30px", height: "30px" }} color="black" />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {isMobile && (
                <div id={isAndroidunity === true ? "fullscreenButton" : undefined} className='d-flex align-items-center justify-content-center' style={{ background: "#222222", position: "relative" }}>
                  {(showplaybutton) && (
                    <div style={{ height: windowHeight, width: "100%", overflow: "hidden" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <img src={wordhunterchallenge} alt="loaderlogo" width="240px" height="135px" style={{ borderRadius: "5px" }} />
                        <button className="btn btn-warning fw-bold mt-2" onClick={mobilescreenfullview}>Play Now</button>
                      </div>
                    </div>
                   </div>
                  )}


                  {/* <div id="fullscreenButton" style={{ background: "#222222" }}> */}
                  <div style={{ position: "relative", touchAction: "none" }}>
                    {/* Rotate your screen*/}
                    {(delayadvertisement && !showplaybutton) && (
                      <div style={{
                        width: "100vw", height: "100vh"
                      }} className="d-flex flex-column align-items-center justify-content-center"  >
                        <h6 className="text-white" style={{ fontWeight: "bold" }}>Advertisement</h6>

                        <div className="mx-2" >

                          <Advertisement3 />
                        </div>
                        {/*  Continue + counter OR Countinue */}
                        <>
                          {buttonCounter > 0 ? <div className="mx-2"> <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounter}</p></div>
                            :
                            <>
                              <div className="mx-2" style={{ textAlign: "center", cursor: "pointer", zIndex: (showsplash === false) ? "1" : "0" }} >
                                <img src={continueyellow2} alt="playbutton" onClick={handleButtonClicks} className="buttonyellowcontinue" />
                              </div>
                            </>
                          }
                        </>
                      </div>

                    )}

                    {/*  Advertisement */}

                  </div>
                  {(showRotateYourScreen) && (
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: "100vw", height: "100vh", position: "absolute", top: "0px", background: "#222222", zIndex: 11111111, touchAction: "none" }}>
                      <MdScreenRotation style={{ color: "white", width: "150px", height: "150px", }} />
                      <h1 className="text-white">Rotate your screen</h1>                    </div>
                  )}



                  {showButtons && (

                    <div style={{ width: isLoaded == false ? "100%" : "100% ", height: isLoaded == false ? windowHeight : windowHeight, overflow: "hidden", position: "relative", touchAction: "none" }}>
                      {isLoaded == false && (
                        <>
                          <div style={{ textAlign: "center", paddingTop: "50%", paddingBottom: "50%" }}>
                            <img src={wordhunterchallenge} className="imagesplash mb-2" />
                            <div>
                              <span className="text-white fw-bold mb-2" style={{ fontSize: "24px" }}>Word Hunter's Challenge</span>

                            </div>

                            {/* Sidhu Modified Mobile Progress Bar - 22.04.2024 */}

                            <div style={{ paddingLeft: "10%", paddingRight: "10%", width: "100%" }}>
                              {loadingProgression == 0 && (
                                <div className="demo-container mt-2">
                                  <div className="progress-bar-style rounded">
                                    <div className="progress-bar-value"></div>
                                  </div>
                                </div>
                              )}
                              {loadingProgression > 0 && (
                                <div className="mt-2" >
                                  < Progress
                                    status="active"
                                    theme={{ active: { color: '#f1c70a' } }}
                                    percent={(loadingProgressionValue * 4.5) < 100 ? (loadingProgressionValue * 4.5) : 100} />
                                </div>
                              )}
                            </div>
                            {(loadingProgressionValue * 4.5) < 101 ? (
                              <p className="text-white">
                                Please wait. Building Your Gaming World!... {(loadingProgressionValue * 4.5) < 100 ? (loadingProgressionValue * 4.5) : 100}%
                              </p>
                            ) : (
                              <p className="text-white">
                                Starting the Game...
                              </p>
                            )}
                          </div>
                        </>
                      )}

                      <div style={{ position: "relative" }}>

                        <Unity
                          style={{
                            width: windowWidth,
                            height: (isIphoneUnity === true || isIpadUnity === true) ? windowHeight : "100vh",
                            aspectRatio: "9/16",
                            unitySelf: "center",
                            alignSelf: "center",
                          }}
                          unityProvider={unityProvider}
                        />


                        <div style={delayad && isGameOver ? { position: "absolute", top: "0%", right: "0%", background: "#222222", textAlign: "center", width: "100%", height: windowHeight } : { position: "absolute", top: "-10000000px" }}>
                          <div style={{ paddingBottom: "50%", paddingTop: "50%" }}>
                            <div style={{ position: "relative" }}>

                              <div className="d-flex flex-column align-items-center justify-content-center">



                                {!refreshAdd && (<div id="advertisemented" >
                                  <h6 className="text-white" style={{ fontWeight: "bold" }}>Advertisement</h6>
                                  <Advertisement3 />

                                </div>)}



                                {/* {buttonCounters > 0 ? (
              <div className="mt-1">
                <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounters}</p>
              </div>
            ) : ( */}
                                <div className=" mt-1" style={{ textAlign: "center", cursor: "pointer" }}>
                                  <img src={continueyellow2} alt="playbutton" onClick={Advertisementafterplay} className="buttonyellowcontinue" />
                                </div>
                                {/* )} */}
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div style={{ position: "absolute", top: "2%", right: 0, }}>
                        <button onClick={backNavigation} className="backnavigationrightbutton" >
                          <SlArrowLeft color="white" />
                          <img src={rgblacklogo} style={{ width: "25px", height: "25px" }} />
                        </button>
                      </div>
                    </div>
                  )}
                  {(changevisibility && !showplaybutton) && (
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: windowHeight, width: "100vw", position: "absolute", top: "0px", background: "#222222", zIndex: 111111111111, touchAction: "none", overflow: "hidden" }}>
                      <img src={wordhunterchallenge} alt="loaderlogo" width="240px" height="135px" style={{ borderRadius: "5px" }} />
                      <span className="text-white fw-bold mb-2" style={{ fontSize: "24px" }}>Word Hunter's Challenge</span>
                      <button className="btn btn-warning fw-bold mt-2" onClick={handleResumeButtonClick}>Continue Playing</button>
                    </div>
                  )}
                </div>

                // </div>
              )}
              {/* <div className="pb-2 pt-1 mt-1 " style={{ background: "#222222", display: (showplaybutton === false && isMobile === true) && "none" }}>
                <div className="d-flex align-items-between justify-content-between px-3">
                  <BottomMenu isButtonRed={isButtonRed} onButtonClick={openFullscreen} gameId="16" gameImage={wordhunterchallenge} gameName="Word Hunter's Challenge" link="/word-hunter-challenge" />
                </div>
              </div> */}
              {/* <div className="mt-3" style={{ display: (showplaybutton === false && isMobile === true) && "none" }}>
                <Addadds />
              </div >
              <div style={{ textAlign: "justify", display: (showplaybutton === false && isMobile === true) && "none" }} className="text-white px-3 mt-3 ">
                <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "600" }}>Word Hunter's Challenge</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>"Word Hunter's Challenge" is an exciting word game that will put your vocabulary and puzzle-solving skills to the test!</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>In this thrilling challenge, players embark on a quest to hunt down hidden words within a grid of letters. As a word hunter, your task is to scour the grid and locate all the words hidden within it. These words can be arranged horizontally, vertically, diagonally, and even backwards!</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>With each word you find, you'll earn points and advance to the next level of difficulty. But beware - the grid becomes more challenging as you progress, with longer words and trickier placements to test your skills.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>"Word Hunter's Challenge" offers endless hours of entertainment for players of all ages. Whether you're a seasoned word enthusiast or just looking for a fun way to sharpen your mind, this game is sure to keep you engaged and entertained.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>So, are you ready to embark on the ultimate word-hunting adventure? Put your skills to the test and see if you have what it takes to conquer the "Word Hunter's Challenge"!</h6>
                <h6 style={{ fontSize: "18px", }} className="fontweight mb-3">How To Play?</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">"Hunt the words  in <b>Word Hunter's Challenge"</b></h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">1. Click the <b>Play</b> button to navigate to the categories section.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">2. In the <b>categories</b> section, select the desired category by tapping on it.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">3. Once you've chosen a category, tap on a specific <b>level</b> to begin gameplay.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">4. In each level, you'll see a <b>list of words</b> at the top of the screen. Your task is to find these words within the gameplay.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">5. After finding all the required words, you'll proceed to the <b>next level</b> automatically.</h6>

                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Controls</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>DESKTOP : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 1. Use the <b>left mouse button</b> to click and drag the letters to combine them.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>MOBILE : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 1. <b>Tap</b> and <b>drag</b> on the letters to combine the letters </h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Category</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Educational, Word Puzzle</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Tags</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Word game, Vocabulary challenge, Puzzle-solving skills, Exciting gameplay, Hidden words, Grid of letters, Quest, Word hunter, Scouring the grid, Horizontal words, Vertical words, Diagonal words, Backwards words, Points system, Increasing difficulty, Endless entertainment, All ages, Word enthusiast, Mind sharpening, Engaging gameplay, Ultimate word-hunting adventure.</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Release Date</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Updates</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Build Number : 011</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Developer</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Revolution Games Private Limited</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Platforms</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Web browser (desktop and mobile)</h6>
              </div> */}
            </div>
            {/* <div className="" style={{ border: "1px solid black", display: (showplaybutton === false && isMobile === true) && "none", width: isMobile === true ? "0%" : "16.6%" }}>

              <div className={`${isMobile === true ? "px-0 mt-2" : "px-2 mt-2"}} }`}  >
                <BottomListGameRight searchQuery={searchQuery} sendMessageToUnity={sendmessagetounity} excludeIds="16" height={divHeight} />
              </div>
            </div> */}
          </div>

        </div>

        {/* <div className="mt-4" style={{ display: (showplaybutton === false && isMobile === true) && "none" }}>
          <BottomListGame searchQuery={searchQuery} sendMessageToUnity={sendmessagetounity} excludeIds="16" />
        </div>
        <div className="" style={{ display: (showplaybutton === false && isMobile === true) && "none" }}>
          <FooterPortion />
        </div> */}
      </div>
    </>
  );
}


export default WordHuntersChallenge;
