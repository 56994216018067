import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylecss/styles.css'
import JoinCode from "./headerfooter/codejoins";
import WordHuntersChallenge from "./gamescreen/wordhunterschallenge";
import MainCode from "./headerfooter/mainflow";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

class AppRouter extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {

        const firebaseConfig = {
            apiKey: "AIzaSyDblZv_tlE-B67LqF0iyQioDZ0RK8s7j7U",
            authDomain: "word-hunter-s--website.firebaseapp.com",
            projectId: "word-hunter-s--website",
            storageBucket: "word-hunter-s--website.appspot.com",
            messagingSenderId: "233642489399",
            appId: "1:233642489399:web:5d1ed9d533491b5350b1b2",
            measurementId: "G-QVJGM3XKYZ"
          };

          const app = initializeApp(firebaseConfig);
        
          const analytics = getAnalytics(app);
        return (
            <Router>

                <Routes>
                    <Route exact path="/" element={<MainCode />} />
                    {/* <Route exact path="/wordhunterschallenge" element={<WordHuntersChallenge />} /> */}




                </Routes>

            </Router>
        );
    }
}

export default AppRouter;