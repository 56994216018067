import React, {useState} from "react";
import JoinCode from "./codejoins";
import WordHuntersChallenge from "../gamescreen/wordhunterschallenge";
import { getAnalytics, logEvent } from "firebase/analytics";


const MainCode = () => {
    const [showGame, setShowGame] = useState(false);
    const analytics = getAnalytics();

    const handleButtonClick = () => {
        logEvent(analytics, "Word Hunter's Challenge Clicked");
        setShowGame(!showGame);
    };
  
    return (
      <div>
        {showGame ? (
          <WordHuntersChallenge onButtonClick={handleButtonClick} />
        ) : (
          <JoinCode onButtonClick={handleButtonClick} />
        )}
      </div>
    );
  };
  
  export default MainCode;