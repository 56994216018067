import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as APIConstants from "../apistore/apiconstants";

// API call
export const getprofile = createAsyncThunk('profile/getprofile', async (payload) => {
    
  const apiURL = APIConstants.APIbaseURLs + APIConstants.getprofileAPI;
  const response = await axios.post(apiURL, payload);
  // console.log(" get profile responses",response);
  return response.data;
})

export const updateprofile = createAsyncThunk('profile/updateprofile', async (payload) => {
    
    const apiURL = APIConstants.APIbaseURLs + APIConstants.updateprofileAPI;
    const response = await axios.post(apiURL, payload);
    // console.log(" update profile responses",response);
    return response.data;
  })




// reducer
export const getprofilelistSlice = createSlice({
  name: 'profile',
  initialState: {
    data: [],
    loading: 'idle',
    updatedata: [],
    updateloading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => (
    builder.addCase(getprofile.pending, (state, action) => {
      // console.log("getting1", state.loading, action);
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    }),
    builder.addCase(getprofile.fulfilled, (state, action) => {
      // console.log("getting2", state.loading);
      if (state.loading === 'pending') {
        state.data = action.payload;
        state.loading = 'idle';
      }
    }),
    builder.addCase(getprofile.rejected, (state, action) => {
      // console.log("getting3", state.loading, action);
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = 'Error occured';
      }
    }),

    builder.addCase(updateprofile.pending, (state, action) => {
      // console.log("getting1", state.updateloading, action);
      if (state.updateloading === 'idle') {
        state.updateloading = 'pending';
      }
    }),
    builder.addCase(updateprofile.fulfilled, (state, action) => {
      // console.log("getting2", state.updateloading);
      if (state.updateloading === 'pending') {
        state.updatedata = action.payload;
        state.updateloading = 'idle';
      }
    }),
    builder.addCase(updateprofile.rejected, (state, action) => {
      // console.log("getting3", state.updateloading, action);
      if (state.updateloading === 'pending') {
        state.updateloading = 'idle';
        state.error = 'Error occured';
      }
    })
  ),
});

export default getprofilelistSlice.reducer;