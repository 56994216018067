import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as APIConstants from "../apistore/apiconstants";

//API call
export const forget_password_APIcall = createAsyncThunk('userForgotPasswordData/forget_password_APIcall', async (payload) => {
    let apiURL = APIConstants.APIbaseURLs + APIConstants.forgotpwdAPI;
    const response = await axios.post(apiURL, payload);
    // console.log(response);
    return response.data;
})

export const reset_password_APIcall = createAsyncThunk('userForgotPasswordData/reset_password_APIcall', async (payload) => {
    let apiURL = APIConstants.APIbaseURLs + APIConstants.resetpwdAPI;
    const response = await axios.post(apiURL, payload);
    // console.log(response, "get reset password API call");
    return response.data;
})

export const forget_password_slice = createSlice({
    name: 'register',
    initialState: {
        data: [],
        loading: 'idle',
        resetdata: [],
        resetloading: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => (
      builder.addCase(forget_password_APIcall.pending, (state, action) => {
        // console.log("getting1", state.loading, action);
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      }),
      builder.addCase(forget_password_APIcall.fulfilled, (state, action) => {
        // console.log("getting2", state.loading);
        if (state.loading === 'pending') {
          state.data = action.payload;
          state.loading = 'idle';
        }
      }),
      builder.addCase(forget_password_APIcall.rejected, (state, action) => {
        // console.log("getting3", state.loading, action);
        if (state.loading === 'pending') {
          state.loading = 'idle';
          state.error = 'Error occured';
  
        }
      }),
  
      builder.addCase(reset_password_APIcall.pending, (state, action) => {
          // console.log("getting1", state.resetloading, action);
          if (state.resetloading === 'idle') {
            state.resetloading = 'pending';
          }
        }),
        builder.addCase(reset_password_APIcall.fulfilled, (state, action) => {
          // console.log("getting2", state.resetloading);
          if (state.resetloading === 'pending') {
            state.resetdata = action.payload;
            state.resetloading = 'idle';
          }
        }),
        builder.addCase(reset_password_APIcall.rejected, (state, action) => {
          // console.log("getting3", state.resetloading, action);
          if (state.resetloading === 'pending') {
            state.resetloading = 'idle';
            state.error = 'Error occured';
  
          }
        })
    
    ),
  });




export default forget_password_slice.reducer;